import React, { useEffect, useState } from 'react';
import { Card, Cascader, Tabs } from 'antd';
import store from "./store";
import { SearchForm } from "components";
import { observer } from "mobx-react";
import css from './home.module.less';
import Map from './Map';


  export default observer(function () {
    const [type, setType] = useState(store.type);

    useEffect(() => {
      store.location = []
      store.fetchRecords()
      store.fetchUsers()
      store.resetSearch()
    }, [store.page, store.pageSize, type])

    useEffect(() => {
      if (store.page === 1) {
        store.fetchRecords()
      } else {
        store.set('page', 1)
      }
    }, [store.f_tab])

    return (
      <React.Fragment>
        <Card>
          <Tabs defaultActiveKey="2" onChange={e => {
            setType(e)
            store.set('type', e)
          }}>
            <Tabs.TabPane tab="按车辆所在城市" key="2" />
            <Tabs.TabPane tab="按车辆所属仓库" key="1" />
          </Tabs>
          <SearchForm reset={store.resetSearch} search={store.handleSearch}>
            <SearchForm.Item title={type === "2" ? "车辆所在城市" : "车辆所属仓库"}>
              <Cascader options={store.records.items}
                        changeOnSelect
                        value={store.location}
                        onChange={store.eSet('location')}
                        fieldNames={{ label: 'name', value: 'name' }}
                        displayRender={label => label.join(' ')}
                        placeholder="请选择" />
            </SearchForm.Item>
          </SearchForm>
          <div className={css.data}>
            <div className={css.list}>
              <div className={css.h1}>{store.records?.kzl ?? '--'}</div>
              <div className={css.h2}>可租赁</div>
            </div>
            <div className={css.line} />
            <div className={css.list}>
              <div className={css.h1}>{store.records?.yzl ?? '--'}</div>
              <div className={css.h2}>已租赁</div>
            </div>
            <div className={css.line} />
            <div className={css.list}>
              <div className={css.h1}>{store.records?.djf ?? '--'}</div>
              <div className={css.h2}>待交付</div>
            </div>
            <div className={css.line} />
            <div className={css.list}>
              <div className={css.h1}>{store.records?.dsc ?? '--'}</div>
              <div className={css.h2}>待收车</div>
            </div>
            <div className={css.line} />
            <div className={css.list}>
              <div className={css.h1}>{store.records?.rent_days ?? '--'}</div>
              <div className={css.h2}>平均租车周期</div>
            </div>
          </div>
          <div className={css.mapBox}>
            <Map data={store.records.data} />
          </div>
        </Card>
      </React.Fragment>
    )
  })