import http from 'axios'
import {Modal, message} from 'antd';
import {ExclamationCircleOutlined} from '@ant-design/icons';

let visible = false // 版本更新提示是否显示

// response处理
function handleResponse(response) {
  let result;
  if (response.status === 401) {
    result = '会话过期，请重新登录。'
    if (window.location.pathname !== '/') {
      if (!visible) {
        visible = true
        Modal.warning({
          content: result,
          onOk: () => window.location.href = '/'
        })
      }
      return Promise.resolve()
    }
  } else if (response.status === 417) {
    if (!visible) {
      visible = true
      Modal.warning({
        title: '版本更新提示',
        icon: <ExclamationCircleOutlined/>,
        content: '有新版本发布，页面需要刷新！',
        okText: '刷新页面',
        cancelText: '',
        onOk: window.location.reload
      })
    }
    return Promise.reject('有新版本发布，页面需要刷新！')
  } else if (response.status === 200) {
    if (response.data.error) {
      result = response.data.error
    } else if (response.data.hasOwnProperty('data')) {
      return Promise.resolve(response.data.data)
    } else if (response.headers['content-type'] === 'application/octet-stream') {
      return Promise.resolve(response)
    } else {
      result = '无效的数据格式'
    }
  } else if (response.status === 204) {
    // 204 No Content：服务器成功处理了请求，但没返回任何内容。
    return Promise.resolve(response)
  } else {
    result = `请求失败: ${response.status} ${response.statusText}`
  }
  message.error(result);
  return Promise.reject(result)
}

// 请求拦截器
http.interceptors.request.use(request => {
  if (request.url.startsWith('/web/')) {
    request.headers['X-TOKEN'] = localStorage.getItem('token')
    request.headers['X-Version'] = '202109231'//当前项目版本号
  }
  request.timeout = request.timeout ?? 300000;
  return request;
});

// 返回拦截器
http.interceptors.response.use(response => {
  return handleResponse(response)
}, error => {
  if (error.response) {
    return handleResponse(error.response)
  }
  const result = '请求异常: ' + error.message;
  message.error(result);
  return Promise.reject(result)
});

export default http;
