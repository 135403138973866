import React, { useState } from 'react';
import { Modal, Form, Input, message, Select } from 'antd';
import { http } from 'libs';
import store from './store';
import { Upload } from "components";

export default function IForm() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const actions = {
    url: '添加第三方检测URL',
    car_cover: '上传车辆照片',
    dispatch: '选择运营专员',
  }


  function handleSubmit() {
    setLoading(true);
    const formData = form.getFieldsValue();
    formData['id'] = store.record.id;
    http.patch('/web/lutesi/detect/', formData)
      .then(() => {
        message.success('操作成功');
        store.set('formVisible', false);
        store.fetchRecords()
      }, () => setLoading(false))
  }

  return (
    <Modal
      open
      maskClosable={false}
      title={actions[store.formAction]}
      onCancel={store.vSet('formVisible', false)}
      confirmLoading={loading}
      onOk={handleSubmit}>
      <Form form={form} initialValues={store.record} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }}>
        {
          store.formAction !== 'car_cover' ? null : (
            <Form.Item name="third_car_cover" label='车辆照片' rules={[{ required: true }]}>
              <Upload path="car_cover" />
            </Form.Item>
          )
        }
        {store.formAction !== 'url' ? null : (
          <Form.Item required name="third_detect_url" label="第三方检测url">
            <Input placeholder="请输入" />
          </Form.Item>)
        }
        {store.formAction !== 'dispatch' ? null : (
          <Form.Item required name="assign_by_id" label="运营专员">
            <Select placeholder="请选择">
              {
                store.assign.map((v, i) => (
                  <Select.Option value={v.id} key={i}>{v.nickname}</Select.Option>
                ))
              }
            </Select>
          </Form.Item>)
        }
      </Form>
    </Modal>
  )
}