import React from 'react';
import { Layout } from 'antd';
import { CopyrightOutlined } from '@ant-design/icons';
import styles from './layout.module.less';

export default function Footer () {
  return (
    <Layout.Footer className={styles.footer}>
      <div>Copyright <CopyrightOutlined/> 2024 By Insight Soft</div>
    </Layout.Footer>
  )
}