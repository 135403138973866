import React, { useEffect, useState } from 'react';
import { Modal, Form, message, Row, Col, Input, Select, Divider, Cascader } from 'antd';
import { http } from 'libs';
import { Upload, ADatePicker } from "components";
import store from './store';
import { city } from "libs/divisions";

export default function IForm() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const actions = {
    stock: '入库',
    car_cover: '上传车辆照片',
    change_stock: '调拨仓库',
    third_car_type: '编辑车辆信息'
  }


  useEffect(() => {
    if (store.record.stock_status === '1') {
      form.setFieldValue('stock', store.record.pre_stock)
    }
  }, [store.record])

  function handleSubmit() {
    const formData = form.getFieldsValue();
    formData['id'] = store.record.id;
    setLoading(true);
    http.patch('/web/lutesi/stock/', formData)
      .then(() => {
        message.success('操作成功');
        store.set('formVisible', false);
        store.fetchRecords()
        store.fetchSummary()
      }, () => setLoading(false))
  }

  return (
    <Modal
      open
      width={store.formAction === 'third_car_type' ? 700 : 450}
      maskClosable={false}
      title={actions[store.formAction]}
      onCancel={store.vSet('formVisible', false)}
      confirmLoading={loading}
      onOk={form.submit}>
      <Form form={form}
        onFinish={handleSubmit}
        initialValues={store.record}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 14 }}>
        {
          store.formAction !== 'car_cover' ? null : (
            <Form.Item name="third_car_cover" label='车辆照片' rules={[{ required: true }]}>
              <Upload path="car_cover" />
            </Form.Item>
          )
        }
        {
          store.formAction !== 'stock' ? null : (
            <Form.Item name="stock"
              label={actions[store.formAction]}
              rules={[{ required: true, message: '请选择' }]}>
              <Select placeholder="请选择" >
                <Select.Option value='路特斯-北京仓'>路特斯-北京仓</Select.Option>
                <Select.Option value='路特斯-上海仓'>路特斯-上海仓</Select.Option>
                <Select.Option value='路特斯-武汉仓'>路特斯-武汉仓</Select.Option>
                <Select.Option value='路特斯-杭州仓'>路特斯-杭州仓</Select.Option>
                <Select.Option value='路特斯-成都仓'>路特斯-成都仓</Select.Option>
                <Select.Option value='路特斯-海口仓'>路特斯-海口仓</Select.Option>
              </Select>
            </Form.Item>
          )
        }
        {
          store.formAction !== 'change_stock' ? null : (
            <>
              <Form.Item label='当前仓库'>
                <div>{store.record.stock_status === '1' ? store.record.pre_stock : store.record.stock}</div>
              </Form.Item>
              <Form.Item name="change_stock"
                label='调入仓库'
                rules={[{ required: true, message: '请选择' }]}>
                <Select placeholder="请选择" >
                  <Select.Option value='路特斯-北京仓'>路特斯-北京仓</Select.Option>
                  <Select.Option value='路特斯-上海仓'>路特斯-上海仓</Select.Option>
                  <Select.Option value='路特斯-武汉仓'>路特斯-武汉仓</Select.Option>
                  <Select.Option value='路特斯-杭州仓'>路特斯-杭州仓</Select.Option>
                  <Select.Option value='路特斯-成都仓'>路特斯-成都仓</Select.Option>
                  <Select.Option value='路特斯-海口仓'>路特斯-海口仓</Select.Option>
                </Select>
              </Form.Item>
            </>
          )
        }
        {
          store.formAction !== 'third_car_type' ? null : (
            <>
              <h4><strong>基础信息</strong></h4>
              <Row>
                <Col span={11}>
                  <Form.Item name="third_car_cover" label='车辆照片' >
                    <Upload path="car_cover" />
                  </Form.Item>
                  <Form.Item name="car_plate_number" label='车牌号'>
                    <Input />
                  </Form.Item>
                  <Form.Item name="car_vin" label='车架号' rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                  <Form.Item name="activity_label" label="活动标签">
                    <Input.TextArea placeholder="请输入" rows={2} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="lotus_sn" label='路特斯编号' >
                    <Input />
                  </Form.Item>
                  <Form.Item name="car_series" label='车系'>
                    <Input />
                  </Form.Item>
                  <Form.Item name="car_year" label='年款'>
                    <Input />
                  </Form.Item>
                  <Form.Item name="car_model" label='车型'>
                    <Input />
                  </Form.Item>
                  <Form.Item name="car_motor" label='电机'>
                    <Select placeholder="请选择" >
                      <Select.Option value='L+'>L+</Select.Option>
                      <Select.Option value='S+'>S+</Select.Option>
                      <Select.Option value='R+'>R+</Select.Option>
                      <Select.Option value='I4'>I4</Select.Option>
                      <Select.Option value='V6'>V6</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item name="car_seats" label='座位'>
                    <Select placeholder="请选择" >
                      <Select.Option value='4座'>4座</Select.Option>
                      <Select.Option value='5座'>5座</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item name="car_color" label='颜色'>
                    <Select placeholder="请选择" >
                      <Select.Option value='花绽灰'>花绽灰</Select.Option>
                      <Select.Option value='纳特隆红'>纳特隆红</Select.Option>
                      <Select.Option value='星际黑'>星际黑</Select.Option>
                      <Select.Option value='索拉黄'>索拉黄</Select.Option>
                      <Select.Option value='加洛韦绿'>加洛韦绿</Select.Option>
                      <Select.Option value='绯花橘'>绯花橘</Select.Option>
                      <Select.Option value='欧卡娅白'>欧卡娅白</Select.Option>
                      <Select.Option value='极光灰'>极光灰</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Divider></Divider>
              <h4><strong>客户信息</strong></h4>
              <Row>
                <Col span={11}>
                  <Form.Item name="phone" label='客户手机号' >
                    <Input disabled />
                  </Form.Item>
                  <Form.Item name="lotus_delivery_time" label="借车日期">
                    <ADatePicker
                      allowClear={false}
                      style={{ width: '100%' }}
                      format="YYYY-MM-DD"
                      placeholder="请选择" />
                  </Form.Item>
                  <Form.Item name="region" label="省市区">
                    <Cascader options={city}
                      fieldNames={{ label: 'name', value: 'name' }}
                      displayRender={label => label.join(' ')}
                      placeholder="请选择" />
                  </Form.Item>
                  <Form.Item name="detail" label="详细地址">
                    <Input.TextArea placeholder="请输入" rows={2} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="use_by_name" label='用车人' >
                    <Input />
                  </Form.Item>
                  <Form.Item name="lotus_return_time" label="拟还车日期">
                    <ADatePicker
                      allowClear={false}
                      style={{ width: '100%' }}
                      format="YYYY-MM-DD"
                      placeholder="请选择" />
                  </Form.Item>
                  <Form.Item name="use_by_department" label='用车部门'>
                    <Select placeholder="请选择" >
                      <Select.Option value='品牌传播中心'>品牌传播中心</Select.Option>
                      <Select.Option value='社交媒体'>社交媒体</Select.Option>
                      <Select.Option value='区域销售'>区域销售</Select.Option>
                      <Select.Option value='机器人业务线'>机器人业务线</Select.Option>
                      <Select.Option value='市场营销部'>市场营销部</Select.Option>
                      <Select.Option value='技术认证部'>技术认证部</Select.Option>
                      <Select.Option value='外部客户'>外部客户</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Divider></Divider>
              <h4><strong>状态信息</strong></h4>
              <Row>
                <Col span={11}>
                  <Form.Item name="third_car_status" label='车辆状态'>
                    <Select placeholder="请选择" >
                      <Select.Option value='维修中'>维修中</Select.Option>
                      <Select.Option value='待退役'>待退役</Select.Option>
                      <Select.Option value='正常使用中'>正常使用中</Select.Option>
                      <Select.Option value='海外车辆'>海外车辆</Select.Option>
                      <Select.Option value='已处置'>已处置</Select.Option>
                      <Select.Option value='售后培训'>售后培训</Select.Option>
                      <Select.Option value='整备中'>整备中</Select.Option>
                      <Select.Option value='不可使用'>不可使用</Select.Option>
                      <Select.Option value='待回收'>待回收</Select.Option>
                      <Select.Option value='已交付'>已交付</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item name="lotus_plate_type" label='牌照类型'>
                    <Select placeholder="请选择" >
                      <Select.Option value='未上牌'>未上牌</Select.Option>
                      <Select.Option value='试验临牌'>试验临牌</Select.Option>
                      <Select.Option value='正式临牌'>正式临牌</Select.Option>
                      <Select.Option value='铁牌'>铁牌</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item name="appendix" label="附记录">
                    <Input.TextArea placeholder="请输入" rows={2} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="asset_status" label='资产状态'>
                    <Select placeholder="请选择" >
                      <Select.Option value='固定资产'>固定资产</Select.Option>
                      <Select.Option value='存货'>存货</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item name="market" label='市场'>
                    <Select placeholder="请选择" >
                      <Select.Option value='中国'>中国</Select.Option>
                      <Select.Option value='海外'>海外</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )
        }
      </Form>
    </Modal >
  )
}