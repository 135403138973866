import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import {Input, DatePicker, Button} from 'antd';
import { SearchForm, Breadcrumb } from 'components';
import Table from './Table';
import Form from './Form';
import store from './store';

export default observer(function () {
  useEffect(() => {
    store.fetchRecords()
  }, [])

  return (
    <React.Fragment>
      <Breadcrumb>
        <Breadcrumb.Item>系统管理</Breadcrumb.Item>
        <Breadcrumb.Item>角色管理</Breadcrumb.Item>
      </Breadcrumb>
      <SearchForm reset={store.resetSearch} search={store.handelSearch}>
        <SearchForm.Item title="角色名">
          <Input value={store.f_name} onChange={store.eSet('f_name')} placeholder="请输入"/>
        </SearchForm.Item>
      </SearchForm>
      <Table/>
      {store.formVisible && <Form/>}
    </React.Fragment>
  )
})
