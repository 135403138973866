import React from 'react';
import { observer } from 'mobx-react';
import { Radio, Image, Typography, Tag } from 'antd';
import { TableCard, AuthDiv, AuthButtonV } from 'components';
import { data, } from 'libs';
import store from './store';


export default observer(function () {
  const maxWidth = {
    wordBreak: 'break-all',
    maxWidth: '12vw'
  }

  const tabs = [
    { value: "1", label: `待整备(${store.summary["zb_dzb"] || 0})` },
    { value: "2", label: `待审核(${store.summary["zb_dsh"] || 0})` },
    { value: "3", label: `整备中(${store.summary["zb_zbz"] || 0})` },
    { value: "4", label: `已完成(${store.summary["zb_ywc"] || 0})` },
    { value: "", label: `全部(${store.summary["zb_qb"] || 0})` },
  ]



  const columns = [{
    title: '整备订单号',
    render: info => <div style={maxWidth}>
      <div>{info.sn}</div>
      <div style={{ color: '#717171' }}>{info.submit_at}</div>
      <div style={{ color: '#717171' }}>{info.submit_by_name}</div>
    </div>
  }, {
    title: '车辆照片',
    dataIndex: 'third_car_cover',
    render: (value, info) => value ?
      <Image src={info.third_car_cover} fallback={data.picFallBack} width={140} style={{ maxHeight: 140 }} />
      : (
        <AuthDiv auth="prepare.car_cover" onClick={() => store.showForm(info, 'car_cover')}>
          <Typography.Link>上传</Typography.Link>
        </AuthDiv>
      )
  }, {
    title: '车辆详情',
    render: info => <div style={maxWidth}>
      <span><div style={{ color: '#000000' }}>{info.car_full_model}</div></span>
      <div style={{ color: '#717171' }}>{info.car_plate_number} {info.car_color}</div>
      <div style={{ color: '#717171' }}>{info.car_mileage}</div>
      <div style={{ color: '#717171' }}>{info.car_vin}</div>
      <div>{info.third_car_type ? <span><Tag color="#000000">{info.third_car_type}</Tag></span> : null}</div>
    </div>
  }, {
    title: 'GPS位置',
    render: info => <div style={maxWidth}>
      <div>{info.region[0]}{info.region[1]}{info.region[2]}{info.detail}</div>
      <div style={{ color: '#717171' }}>{info.location}</div>
    </div>
  }, {
    title: '整备状态',
    render: info => info.status === '1' ? <div style={maxWidth}>{info.status_alias}</div> : <div style={maxWidth}>
      <div>{info.prepare_items.map(x => <div>{x.name} {x.money}</div>)}</div>
      <div style={{ color: '#717171' }}>预计{info.prepare_days}天</div>
      {info.status === '4' ? <div style={{ color: '#717171' }}>实际{info.cost_days}天</div> : null}
    </div>
  }, {
    title: '审核状态',
    render: info => <div style={maxWidth}>
      <div>{info.approve_status_alias}</div>
      <div style={{ color: '#717171' }}>{info.approve_by_name}</div>
    </div>
  }, {
    title: '施工状态',
    render: info => <div style={maxWidth}>
      <div>{info.execute_status.map(x => <div>{x}</div>)}</div>
    </div>
  }, {
    title: '质检状态',
    render: info => <div style={maxWidth}>
      <div>{info.inspect_status.map(x => <div>{x}</div>)}</div>
    </div>
  }, {
    title: '终检状态',
    render: info => <div style={maxWidth}>
      <div>{info.final_inspect_status}</div>

    </div>
  }, {
    title: '操作',
    fixed: 'right',
    align: 'center',
    render: info => <>
      <AuthButtonV auth="prepare.approve"
        onClick={() => store.showForm(info,'approve')}
        hidden={info.status !== '2'}
      >
        审核
      </AuthButtonV>
      <AuthButtonV auth="prepare.url_inner"
        href={'/pages/carDetail/index.html?id=' + info.car_sn}
        target="_blank">
        查看车辆（内部版)
      </AuthButtonV>
      {/*<AuthButtonV auth="prepare.url_out"*/}
      {/*  href={'/pages/carOutDetail/index.html?id=' + info.car_sn}*/}
      {/*  target="_blank">*/}
      {/*  查看车辆（外部版)*/}
      {/*</AuthButtonV>*/}
    </>
  },]


  return (
    <TableCard
      title="整备列表"
      rowKey="id"
      loading={store.fetching}
      dataSource={store.records}
      columns={columns}
      pagination={{
        current: store.page,
        onChange: (page, pageSize) => {
          store.set('page', page)
          store.set('pageSize', pageSize)
        },
        showSizeChanger: true,
        showLessItems: true,
        hideOnSinglePage: false,
        total: store.pageTotal,
        showTotal: (total, range) => `显示第 ${range[0]} 到第 ${range[1]} 条记录，总共 ${total} 条记录`,
        pageSizeOptions: ['10', '20', '50', '100']
      }}
      onReload={store.fetchRecords}
      actions={[
        <Radio.Group optionType={"button"}
          options={tabs}
          value={store.f_tab}
          onChange={store.eSet('f_tab')}
        />
      ]} />
  )
})
