import { makeAutoObservable } from 'mobx';
import { http } from 'libs';

class Store {
  constructor() {
    this.set = (k, v) => {
      if (v && v.nativeEvent) v = v.target.value
      this[k] = v
    }
    this.eSet = k => e => this.set(k, e)
    this.vSet = (k, v) => () => this.set(k, v)
    makeAutoObservable(this)
  }

  records = [];
  assign = []
  summary = {};
  record = {};
  page = 1;
  pageSize = 10;
  pageTotal = 0;
  fetching = false;
  formVisible = false;
  formAction = '';

  f_key = null;
  location = null;
  f_status = null;
  f_tab = '';
  f_assign = null;
  assign_at = null;
  detect_at = null;


  fetchRecords = () => {
    const query = {
      keyword: this.f_key,
      assign_at: this.assign_at?.length ? this.assign_at.map(v => v.format('YYYY-MM-DD')) : '',
      detect_at: this.detect_at?.length ? this.detect_at.map(v => v.format('YYYY-MM-DD')) : '',
      assign_by_id: this.f_assign,
      tab: this.f_tab
    }
    this.fetching = true;
    http.post('/web/lutesi/detect/', { ...query, page: this.page, page_size: this.pageSize })
      .then(res => {
        this.set('records', res.results);
        this.set('pageTotal', res.count)
      })
      .then(() => this.fetchSummary())
      .finally(() => this.set('fetching', false))
  };

  fetchSummary = () => {
    http.get('/web/lutesi/summary/')
      .then(res => this.set('summary', res))
  }

  fetchAssign = () => {
    http.get('/web/lutesi/account/assign/?type=1')
      .then(res => this.set('assign', res))
  }


  showForm = (info, action) => {
    this.record = info ?? {};
    this.formAction = action ?? ''
    this.formVisible = true
  }

  resetSearch = () => {
    this.f_key = null;
    this.f_assign = null;
    this.location = null;
    this.assign_at = null;
    this.detect_at = null;
    this.f_tab = null;
  }

  pageChange = (page, size) => {
    this.records = []
    this.page = page;
    this.pageSize = size;
    this.fetchRecords()
  }

  handleSearch = () => {
    this.page = 1;
    this.records = []
    this.fetchRecords()
  }

}

export default new Store()