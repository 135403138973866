import React from 'react';
import { observer } from 'mobx-react';
import { Radio, Image, Typography, Tag } from 'antd'
import { TableCard, AuthDiv, AuthButtonV } from 'components';
import { data, } from 'libs';
import store from './store';
import moment from 'moment';


export default observer(function () {
  const maxWidth = {
    wordBreak: 'break-all',
    maxWidth: '12vw'
  }

  const tabs = [
    { value: "1", label: `待派单(${store.summary["jf_dpd"] || 0})` },
    { value: "2", label: `待交付(${store.summary["jf_djf"] || 0})` },
    { value: "3", label: `已交付(${store.summary["jf_yjf"] || 0})` },
    { value: "", label: `全部(${store.summary["jf_qb"] || 0})` },
  ]

  const columns = [{
    title: '车辆照片',
    dataIndex: 'third_car_cover',
    render: (value, info) => value ?
      <Image src={info.third_car_cover} fallback={data.picFallBack} width={140} style={{ maxHeight: 140 }} />
      : (
        <AuthDiv auth="delivery.car_cover" onClick={() => store.showForm(info, 'car_cover')}>
          <Typography.Link>上传</Typography.Link>
        </AuthDiv>
      )
  }, {
    title: '车辆详情',
    render: info => <div style={maxWidth}>
      <span><div style={{ color: info.delivery_emergency === 'red' ? '#FF2600' : info.delivery_emergency === 'orange' ? 'FFB200' : '#000000' }}>{info.car_full_model}</div></span>
      <div style={{ color: '#717171' }}>{info.car_plate_number} {info.car_color}</div>
      <div style={{ color: '#717171' }}>{info.car_mileage}</div>
      <div style={{ color: '#717171' }}>{info.car_vin}</div>
      <div>{info.third_car_type ? <span><Tag color="#000000">{info.third_car_type}</Tag></span> : null}</div>
    </div>
  }, {
    title: '租赁订单号',
    width:210,
    render: info => <div style={maxWidth}>
      <div>{info.sn}</div>
      <div style={{ color: '#717171' }}>下单人：{info.created_by_name} </div>
      <div style={{ color: '#717171' }}>{info.created_at} 下单</div>
      <div style={{ color: '#717171' }}>{info.name} / {info.phone}</div>
      <div style={{ color: '#717171' }}>租赁天数：{info.pre_rent_days}天</div>
    </div>
  }, {
    title: '交付信息',
    render: info => <div style={maxWidth}>
      {info.pre_delivery_time ?
        <div style={{ color: info.delivery_emergency === 'red' ? '#FF2600' : info.delivery_emergency === 'orange' ? 'FFB200' : '#000000' }}>
          {new Date(info.pre_delivery_time).getFullYear() === (new Date()).getFullYear() ?
            moment(info.pre_delivery_time).format('MM-DD HH:mm') : info.pre_delivery_time}</div>
        : null}
      <div style={{ color: '#717171' }}>{info.pre_delivery_region[0]}{info.pre_delivery_region[1]}{info.pre_delivery_region[2]}</div>
      <div style={{ color: '#717171' }}>{info.pre_delivery_detail}</div>
    </div>
  }, {
    title: '收车预约信息',
    render: info => <div style={maxWidth}>
      {info.pre_return_time ? <div>{new Date(info.pre_return_time).getFullYear() === (new Date()).getFullYear() ?
            moment(info.pre_return_time).format('MM-DD HH:mm') : info.pre_return_time} 预收车</div> : null}
      <div style={{ color: '#717171' }}>{info.pre_return_region[0]}{info.pre_return_region[1]}{info.pre_return_region[2]}</div>
      <div style={{ color: '#717171' }}>{info.pre_return_detail}</div>
    </div>
  }, {
    title: '车辆状态',
    width:80,
    render: info => <div style={maxWidth}>
      <div>{info.detetc_status_alias}</div>
      <div>{info.prepare_status_alias}</div>
    </div>
  }, {
    title: 'GPS位置',
    width: 200,
    render: info => <div style={maxWidth}>
      <div>{info.region[0]}{info.region[1]}{info.region[2]}{info.detail}</div>
      <div style={{ color: '#717171' }}>{info.location}</div>
    </div>
  }, {
    title: '交付状态',
    width:120,
    render: info => <div style={maxWidth}>
      <div>{info.delivery_status_alias}</div>
      {info.delivery_by_name ? <div style={{ color: '#717171' }}>运营专员：{info.delivery_by_name}</div> : null}
      {info.delivery_time ? <div style={{ color: '#717171' }}>{info.delivery_time}</div> : null}
      {info.delivery_images?.length ? <AuthDiv onClick={() => store.showForm(info, 'images')}>
        <Typography.Link>查看交付签字</Typography.Link>
      </AuthDiv> : null}
    </div>
  }, {
    title: '用车时长',
    width:100,
    render: info => <div style={maxWidth}>
      <div>已用车{info.rent_days ? info.rent_days : 0}天</div>
    </div>
  }, {
    title: '操作',
    fixed: 'right',
    align: 'center',
    render: info => <>
      <AuthButtonV auth="delivery.order"
        onClick={() => store.showForm(info, 'order')}
        hidden={info.delivery_status !== '1'}
      >
        交付派单
      </AuthButtonV>
      <AuthButtonV auth="delivery.edit"
        onClick={() => store.showAddForm(info)}
        hidden={info.delivery_status === '3'}
      >
        编辑
      </AuthButtonV>
      <AuthButtonV auth="delivery.url_inner"
        href={'/pages/carDetail/index.html?id=' + info.car_sn}
        target="_blank">
        查看车辆（内部版)
      </AuthButtonV>
      {/*<AuthButtonV auth="delivery.url_out"*/}
      {/*  href={'/pages/carOutDetail/index.html?id=' + info.car_sn}*/}
      {/*  target="_blank">*/}
      {/*  查看车辆（外部版)*/}
      {/*</AuthButtonV>*/}
    </>
  },]



  return (
    <TableCard
      title="交付列表"
      rowKey="id"
      loading={store.fetching}
      dataSource={store.records}
      columns={columns}
      pagination={{
        current: store.page,
        onChange: (page, pageSize) => {
          store.set('page', page)
          store.set('pageSize', pageSize)
        },
        showSizeChanger: true,
        showLessItems: true,
        hideOnSinglePage: false,
        total: store.pageTotal,
        showTotal: (total, range) => `显示第 ${range[0]} 到第 ${range[1]} 条记录，总共 ${total} 条记录`,
        pageSizeOptions: ['10', '20', '50', '100']
      }}
      onReload={store.fetchRecords}
      actions={[
        <Radio.Group optionType={"button"}
          options={tabs}
          value={store.f_tab}
          onChange={store.eSet('f_tab')}
        />
      ]} />
  )
})
