import { makeAutoObservable } from 'mobx';
import { http } from 'libs';

class Store {
  constructor() {
    this.set = (k, v) => {
      if (v && v.nativeEvent) v = v.target.value
      this[k] = v
    }
    this.eSet = k => e => this.set(k, e)
    this.vSet = (k, v) => () => this.set(k, v)
    makeAutoObservable(this)
  }



  records = [];
  summary = {};
  users = [];
  cars = [];
  record = {};
  page = 1;
  pageSize = 10;
  pageTotal = 0;
  fetching = false;
  formVisible = false;
  pushformVisible = false;
  addformVisible = false;
  importVisible = false;
  formAction = '';

  f_key = null
  stock_status = null; //库存状态
  prepare_status = null; //整备状态
  rent_status = null; //租车状态
  delivery_status = null; //交车状态
  detect_status = null; //评估状态
  location = null;
  stock = null;
  f_name = '';
  f_status = '';
  f_tab = '';
  f_address = null

  pageChange = (page, size) => {
    this.records = []
    this.page = page;
    this.pageSize = size;
    this.fetchRecords()
  }

  handleSearch = () => {
    this.page = 1;
    this.records = []
    this.fetchRecords()
  }

  fetchRecords = () => {
    const query = {
      detect_status: this.detect_status,
      keyword: this.f_key,
      stock_status: this.stock_status,
      prepare_status: this.prepare_status,
      delivery_status: this.delivery_status,
      rent_status: this.rent_status,
      location: this.location,
      stock: this.stock,
      tab: this.f_tab
    }
    this.fetching = true;
    http.post('/web/lutesi/stock/', { ...query, page: this.page, page_size: this.pageSize })
      .then(res => {
        this.set('records', res.results);
        this.set('pageTotal', res.count)
      })
      .then(() => this.fetchSummary())
      .finally(() => this.set('fetching', false))
  };

  fetchSummary = () => {
    http.get('/web/lutesi/summary/')
      .then(res => this.set('summary', res))
  }

  fetchAssign = () => {
    http.get('/web/lutesi/account/assign/?type=1')
      .then(res => this.set('assign', res))
  }

  fetchCars = () => {
    http.get('/web/lutesi/cars/')
      .then(res => this.set('cars', res))
  }

  fetchUsers = () => {
    http.get('/web/lutesi/customer/')
      .then(res => this.set('users', res))
  }


  showForm = (info, action) => {
    this.record = info ?? {};
    this.formAction = action ?? ''
    this.formVisible = true
  }

  showPushForm = (info, action) => {
    this.record = info ?? {};
    this.formAction = action ?? ''
    this.pushformVisible = true
  }

  showImport = (info) => {
    this.record = info ?? {};
    this.importVisible = true
  }

  showAddForm = (info) => {
    this.record = info ?? {};
    this.addformVisible = true
  }

  resetSearch = () => {
    this.f_key = null;
    this.stock_status = null; //库存状态
    this.prepare_status = null; //整备状态
    this.rent_status = null; //租车状态
    this.delivery_status = null; //交车状态
    this.detect_status = null; //评估状态
    this.location = null;
    this.stock = null;
    this.f_tab = "";
  }

}

export default new Store()