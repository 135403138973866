import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Input, Select, Tooltip, DatePicker } from 'antd';
import { SearchForm, Breadcrumb } from 'components';
import Table from './Table';
import Form from './Form';
import store from './store';
import { includes } from "libs";
import { QuestionCircleOutlined } from '@ant-design/icons';

export default observer(function () {
  useEffect(() => {
    store.fetchRecords()
    store.fetchSummary()
    store.fetchAssign()
  }, [store.page, store.pageSize])

  useEffect(() => {
    if (store.page === 1) {
      store.fetchRecords()
    } else {
      store.set('page', 1)
    }
  }, [store.f_tab])

  return (
    <React.Fragment>
      <Breadcrumb>
        <Breadcrumb.Item>检测管理</Breadcrumb.Item>
      </Breadcrumb>
      <SearchForm reset={store.resetSearch} search={store.handleSearch}>
        <SearchForm.Item title={<Tooltip title="品牌、车系、年款、车型、车牌号、车架号">关键字<QuestionCircleOutlined /></Tooltip>}>
          <Input allowClear
            value={store.f_key}
            onChange={store.eSet('f_key')}
            placeholder="品牌、车系、年款、车型、车牌号、车架号" />
        </SearchForm.Item>
        <SearchForm.Item title="检测师">
          <Select placeholder="请选择" value={store.f_assign} onChange={store.eSet('f_assign')}>
            {
              store.assign.map((v, i) => (
                <Select.Option value={v.id} key={i}>{v.nickname}</Select.Option>
              ))
            }
          </Select>
        </SearchForm.Item>
        <SearchForm.Item title="派单日期">
          <DatePicker.RangePicker value={store.assign_at}
            onChange={store.eSet('assign_at')}
            style={{ width: '100%' }} />
        </SearchForm.Item>
        <SearchForm.Item title="检测日期">
          <DatePicker.RangePicker value={store.detect_at}
            onChange={store.eSet('detect_at')}
            style={{ width: '100%' }} />
        </SearchForm.Item>
      </SearchForm>
      <Table />
      {store.formVisible && <Form />}
    </React.Fragment>
  )
})