import React, { useState } from 'react';
import { Modal, Form, Input, message, Radio, Select, InputNumber, Image } from 'antd';
import { http,data } from 'libs';
import store from './store';
import { Upload } from "components";
import css from './index.module.less';

export default function IForm() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const actions = {
    images: '查看照片',
    order: '交付派单',
    car_cover: '上传车辆照片',
  }

  function handleSubmit() {
    setLoading(true);
    const formData = form.getFieldsValue();
    formData['id'] = store.record.id;
    http.patch('/web/lutesi/delivery/', formData)
      .then(() => {
        message.success('操作成功');
        store.set('formVisible', false);
        store.fetchRecords()
        store.fetchSummary()
      }, () => setLoading(false))
  }

  return (
    <Modal
      open
      maskClosable={false}
      title={actions[store.formAction]}
      onCancel={store.vSet('formVisible', false)}
      confirmLoading={loading}
      onOk={handleSubmit}>
      <Form form={form} initialValues={store.record} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }}>

        {store.formAction !== 'order' ? null : (
          <>

            <div style={{ marginLeft: 35, fontSize: 14 }}>车辆信息</div>
            <div style={{ width: '80%', color: '#717171', fontSize: 14, backgroundColor: '#F4F4F4', marginLeft: 35, marginTop: 5, padding: 10, marginBottom: 10 }}>
              <div>{store.record.car_full_model}</div>
              <div>{store.record.car_plate_number} {store.record.car_color}</div>
              <div>{store.record.car_mileage}</div>
              <div>{store.record.car_vin}</div>
            </div>


            <div style={{ marginLeft: 35, fontSize: 14 }}>客户信息</div>
            <div style={{ width: '80%', color: '#717171', fontSize: 14, backgroundColor: '#F4F4F4', marginLeft: 35, marginTop: 5, padding: 10, marginBottom: 20 }}>
              <div>{store.record.name} / {store.record.phone}</div>
              <div>{store.record.pre_delivery_time}</div>
              <div>{store.record.pre_delivery_region[0]}{store.record.pre_delivery_region[1]}{store.record.pre_delivery_region[2]}{store.record.pre_return_detail}</div>
            </div>


            <Form.Item name="type"
              label='交付方式'
              rules={[{ required: true, message: '请选择' }]}>
              <Radio.Group>
                <Radio value="1">代驾</Radio>
                <Radio value="2">板车</Radio>
                <Radio value="3">仓库自送</Radio>
                <Radio value="4">客户自提</Radio>
                <Radio value="5">厂方物流</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item required name="assign_id" label="运营专员">
              <Select placeholder="请选择" style={{ width: '80%' }}>
                {
                  store.assign.map((v, i) => (
                    <Select.Option value={v.id} key={i}>{v.nickname}</Select.Option>
                  ))
                }
              </Select>
            </Form.Item>
            <Form.Item name="money" label="交付金额" >
              <InputNumber min={0} precision={2} style={{ width: '80%' }} addonAfter="元" />
            </Form.Item>
            <Form.Item name="remarks" label="备注">
              <Input.TextArea placeholder="请输入" rows={4} />
            </Form.Item>
          </>)}
        {store.formAction !== 'images' ? null : (
          <div className={css.pic}>
            <Image.PreviewGroup preview>
              {store.record.delivery_images.map((v, i) => (
                <div style={{ marginRight: 5, marginBottom: 5 }}>
                  <Image key={i} src={v} fallback={data.picFallBack} style={{ borderRadius: 5 }}></Image>
                </div>
              ))}
            </Image.PreviewGroup>
          </div>
        )}
        {
          store.formAction !== 'car_cover' ? null : (
            <Form.Item name="third_car_cover" label='车辆照片' rules={[{ required: true }]}>
              <Upload path="car_cover" />
            </Form.Item>
          )
        }
      </Form>
    </Modal>
  )
}