import React, { useState, useEffect } from 'react';
import { Layout, Menu } from 'antd';
import { hasPermission, history } from 'libs';
import styles from './layout.module.less';
import menus from '../routes';
import logo from './u102.png';

let selectedKey = window.location.pathname;
const OpenKeysMap = {};
for (let item of menus) {
  if (item.child) {
    for (let sub of item.child) {
      if (sub.title) OpenKeysMap[sub.path] = item.title
    }
  } else if (item.title) {
    OpenKeysMap[item.path] = 1
  }
}

export default function Sider(props) {
  const [openKeys, setOpenKeys] = useState([]);
  const [items, setItems] = useState([]);

  useEffect(() => {
    let items = menus.map(menu => makeMenu(menu))
    setItems(items)
  }, [])

  function makeMenu(menu) {
    if (menu.auth && !hasPermission(menu.auth)) return null;
    if (!menu.title) return null;
    if (menu.hide) return null;
    return menu.child ? _makeSubMenu(menu) : _makeItem(menu)
  }

  function _makeSubMenu(menu) {
    return {
      key: menu.title,
      label: <a href={menu.path} onClick={e => e.preventDefault()}>{menu.title}</a>,
      icon: menu.icon,
      children: menu.child.map(menu => makeMenu(menu)),
      disabled: menu.disabled,
    }
  }

  function _makeItem(menu) {
    return {
      key: menu.path,
      label: menu.path === '/db' ? <a href={menu.path} target='_blank'>{menu.title}</a> : <a href={menu.path} onClick={e => e.preventDefault()}>{menu.title}</a>,
      icon: menu.icon,
      disabled: menu.disabled,
    }
  }

  selectedKey = window.location.pathname;
  const openKey = OpenKeysMap[selectedKey];
  if (openKey) {
    if (openKey !== 1 && !props.collapsed && !openKeys.includes(openKey)) {
      setOpenKeys([...openKeys, openKey])
    }
  }

  return (
    <Layout.Sider width={208} collapsed={props.collapsed} className={styles.sider}>
      <div className={styles.logo}>
        <img src={logo} alt="Logo" style={{ width: '75%', height: '45%' }} />
      </div>
      <div className={[styles.menus, 'scroll'].join(' ')} style={{ height: 'calc(100vh - 48px)' }}>
        <Menu
          theme="light"
          mode="inline"
          // className={styles.menus}
          items={items}
          selectedKeys={[selectedKey]}
          openKeys={openKeys}
          onOpenChange={setOpenKeys}
          onClick={menu => menu.key === '/db' ? '' : history.push(menu.key)}
        // onSelect={menu => history.push(menu.key)}
        />
      </div>
    </Layout.Sider>
  )
}