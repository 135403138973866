import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';
import 'echarts/extension/bmap/bmap';
import store from './store'
import { observer } from "mobx-react";

export default observer(function () {
  const [data, setData] = useState([])
  useEffect(() => {
    if(store.records.data?.length > 0) {
      setData(store.records.data)
    }
  }, [store.records.data])

  // const geoCoordMap = {
  //   '上海市': [121.472644, 31.231706],
  //   '北京市': [116.405285, 39.904989],
  //   '武汉市': [114.311582, 30.598467],
  //   '杭州市': [120.152791, 30.267446],
  //   '成都市': [104.066143, 30.573095],
  //   '海口市': [110.331190, 20.031971],
  // }
  const convertData = function (data) {
    if (data.length > 0) {
      let res = [];
      for (let i = 0; i < data.length; i++) {
        let geoCoord = store.records.cities[data[i].name];
        if (geoCoord) {
          res.push({
            name: data[i].name,
            value: geoCoord.concat(data[i].value)
          });
        }
      }
      return res;
    } else {
      return []
    }
  };
  let option;
  const chartRef = useRef(null);
  useEffect(() => {
    // 初始化ECharts实例
    const myChart = echarts.init(chartRef.current);
    // 定义图表配置项和数据
    option = {
      backgroundColor: 'transparent',
      tooltip: {
        show: true,
        // triggerOn: "none",
        trigger: 'item',
        formatter: function (data) {
          if (!data) return ""
          let city = data.name;
          let d1 = data.value[2];
          let d2 = data.value[3];
          let d3 = data.value[4];
          let d4 = data.value[5];
          return `${city} <br/>
             可租赁：${d1} <br/>
             已租赁：${d2}<br/>
             待交车：${d3}<br/>
             待还车：${d4}`;
        }
      },
      bmap: {
        center: [114.311582, 30.598467],
        zoom: 5,
        roam: true,
        mapOptions: {
          // 禁用百度地图自带的底图可点功能
          enableMapClick: false
        }
      },
      series: [
        {
          name: 'data',
          type: 'scatter',
          coordinateSystem: 'bmap',
          roam: false,
          data: convertData(data),
          symbol: "image://https://bjm.static.yducc.com.cn/car_cover/b111ae9a82d84fcd98124d5b9898ccf6.png",
          // symbolSize: function (val) {
          //   return val[2] / 10;
          // },
          symbolSize: [28, 28],
          symbolOffset: [0, -14],
          encode: {
            value: 2
          },
          label: {
            formatter: '{b}',
            position: 'right',
            show: false
          },
          emphasis: {
            label: {
              show: true
            }
          },
        },
        {
          // name: 'Top 5',
          type: 'effectScatter',
          coordinateSystem: 'bmap',
          // data: convertData(
          //   data.sort(function (a, b) {
          //     return b.value - a.value;
          //   }).slice(0, 6)
          // ),
          // symbolSize: function (val) {
          //   return val[2] / 10;
          // },
          encode: {
            value: 2
          },
          showEffectOn: 'render',
          rippleEffect: {
            brushType: 'stroke'
          },
          label: {
            formatter: '{b}',
            position: 'right',
            show: true
          },
          itemStyle: {
            shadowBlur: 10,
            shadowColor: '#333'
          },
          emphasis: {
            scale: true
          },
          zlevel: 1
        }
      ]
    };

    if(data.length > 0){
      myChart.hideLoading();
      myChart.setOption(option);
    }else {
      myChart.showLoading();
    }
    // 使用配置项和数据显示图表
    myChart.setOption(option);

    // 组件卸载时清除ECharts实例
    return () => {
      myChart.dispose();
    };
  }, [data]); // 空依赖数组确保仅在组件挂载时运行


  return (
    <div ref={chartRef} style={{height: '500px'}}/>
  );

})