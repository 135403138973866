import {makeAutoObservable} from 'mobx';
import {http, includes} from 'libs';

class Store {
  constructor() {
    this.set = (k, v) => {
      if (v && v.nativeEvent) v = v.target.value
      this[k] = v
    }
    this.eSet = k => e => this.set(k, e)
    this.vSet = (k, v) => () => this.set(k, v)
    makeAutoObservable(this)
  }

  records = [];
  record = {};
  page = 1;
  pageSize = 10;
  pageTotal = 0;
  fetching = false;
  formVisible = false;

  f_name = '';
  f_nickname = '';
  f_status = null;

  get dataSource() {
    let data = this.records;
    if (this.f_name) data = data.filter(x => includes(x.username, this.f_name))
    if (this.f_nickname) data = data.filter(x => includes(x.nickname, this.f_nickname))
    if (this.f_status) data = data.filter(x => (x.is_active ? "1" : "2") === this.f_status)
    return data
  }

  fetchRecords = () => {
    this.fetching = true;
    http.get('/web/lutesi/account/')
      .then(res => this.set('records', res))
      .finally(() => this.set('fetching', false))
  };

  showForm = (info) => {
    this.record = info || {};
    this.formVisible = true
  }
  resetSearch = () => {
    this.f_name = ''
    this.f_nickname = ''
    this.f_status = null
  }
  handelSearch = () => {
    this.fetchRecords()
  }
}

export default new Store()
