import React, { useState } from 'react';
import { Modal, Form, message, Cascader, Input, Radio } from 'antd';
import { http } from 'libs';
import { ADatePicker } from "components";
import store from './store';
import { city } from "libs/divisions";

export default function IForm() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const actions = {
    detect: '推送检测',
    prepare: '推送整备',
  }


  /* useEffect(() => {

  }, [store.record])
 */
  function handleSubmit() {
    const formData = form.getFieldsValue();
    formData['id'] = store.record.id;
    if (store.formAction === 'prepare') {
      formData['action'] = 'prepare'
    } else {
      formData['action'] = 'detect'
    }
    setLoading(true);
    http.put('/web/lutesi/stock/', formData)
      .then(() => {
        message.success('操作成功');
        store.set('pushformVisible', false);
        store.fetchRecords()
        store.fetchSummary()
      }, () => setLoading(false))
  }

  return (
    <Modal
      open
      maskClosable={false}
      title={actions[store.formAction]}
      onCancel={store.vSet('pushformVisible', false)}
      confirmLoading={loading}
      onOk={form.submit}>
      <Form form={form}
        onFinish={handleSubmit}
        initialValues={store.record}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 14 }}>
        {
          store.formAction !== 'prepare' ? null : (
            <>
              <div style={{ marginLeft: 35, fontSize: 14 }}>确定要将该车辆推送整备吗？</div>
              <div style={{ width: '80%', color: '#717171', fontSize: 14, backgroundColor: '#F4F4F4', marginLeft: 35, marginTop: 5, padding: 10 }}>
                <div>{store.record.car_full_model}</div>
                <div>{store.record.car_plate_number} {store.record.car_color}</div>
                <div>{store.record.car_mileage}</div>
                <div>{store.record.car_vin}</div>
              </div>
            </>
          )
        }
        {
          store.formAction !== 'detect' ? null : (
            <>
              <Form.Item name="type"
                label='项目'
                rules={[{ required: true, message: '请选择' }]}>
                <Radio.Group>
                  <Radio value="1">首次检测</Radio>
                  <Radio value="2">常规检测</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item required name="pre_detect_at" label="检测日期">
                <ADatePicker
                  allowClear={false}
                  style={{ width: '100%' }}
                  format="YYYY-MM-DD"
                  placeholder="请选择日期" />
              </Form.Item>
              <Form.Item required name="region" label="省市区">
                <Cascader options={city}
                  fieldNames={{ label: 'name', value: 'name' }}
                  displayRender={label => label.join(' ')}
                  placeholder="请选择" />
              </Form.Item>
              <Form.Item required name="detail" label="详细地址">
                <Input.TextArea placeholder="请输入" />
              </Form.Item>
              {/*隐藏选择运营专员*/}
              {/*<Form.Item noStyle shouldUpdate>*/}
              {/*  {({getFieldValue}) =>*/}
              {/*    getFieldValue('type') === '2' ? (*/}
              {/*      <Form.Item required name="assign_by_id" label="运营专员">*/}
              {/*        <Select placeholder="请选择">*/}
              {/*          {*/}
              {/*            store.assign.map((v, i) => (*/}
              {/*              <Select.Option value={v.id} key={i}>{v.nickname}</Select.Option>*/}
              {/*            ))*/}
              {/*          }*/}
              {/*        </Select>*/}
              {/*      </Form.Item>*/}
              {/*    ) : null*/}
              {/*  }*/}
              {/*</Form.Item>*/}
            </>
          )
        }


      </Form>
    </Modal>
  )
}