import { makeAutoObservable } from 'mobx';
import { http, includes } from 'libs';

class Store {
  constructor() {
    this.set = (k, v) => {
      if (v && v.nativeEvent) v = v.target.value
      this[k] = v
    }
    this.eSet = k => e => this.set(k, e)
    this.vSet = (k, v) => () => this.set(k, v)
    makeAutoObservable(this)
  }

  records = [];
  record = {};
  page = 1;
  pageSize = 10;
  pageTotal = 0;
  fetching = false;
  formVisible = false;

  f_name = '';

  get dataSource() {
    let data = this.records;
    if (this.f_name) data = data.filter(x => includes(x.name, this.f_name))
    return data
  }

  fetchRecords = () => {
    this.fetching = true;
    http.get('/web/lutesi/role/')
      .then(res => this.set('records', res))
      .finally(() => this.set('fetching', false))
  };

  showForm = (info) => {
    this.record = info || {};
    this.formVisible = true
  }
  resetSearch = () => {
    this.f_name = '';
  }
  handelSearch = () => {
    this.fetchRecords()
  }
}

export default new Store()
