import React, { useEffect, useState } from 'react';
import { Descriptions, Tabs } from 'antd';
import { observer } from 'mobx-react';
import { Breadcrumb } from 'components';
import InfoCallback from './InfoCallback';
import InfoOrder from './InfoOrder';
import store from './store';
import css from './index.module.less';
import { history } from "libs";

export default observer(function (props) {
  let id = props.match.params.id
  store.set('userId', id)
  const [activeKey, setActiveKey] = useState('1');
  const tabsItems = [
    { label: '下单记录', key: '1' },
    { label: '回访记录', key: '2' },
  ];

  useEffect(() => {
    store.fetchRecords(id)
    return () => store.set('record',[])
  }, [])

  const extra = (
    <div>
      <Descriptions style={{ padding: '25px 23px 0' }}>
        <Descriptions.Item label="客户名称">{store.record.name}</Descriptions.Item>
        <Descriptions.Item label="联系电话">{store.record.phone}</Descriptions.Item>
        <Descriptions.Item label="客户所在地">{store.record.city} {store.record.area}</Descriptions.Item>
        <Descriptions.Item label="客户备注">{store.record.remarks}</Descriptions.Item>
      </Descriptions>
      <div className={css.tabs}>
        <Tabs items={tabsItems} activeKey={activeKey} onChange={setActiveKey} />
      </div>
    </div>
  )

  return (
    <React.Fragment>
      <Breadcrumb extra={extra}>
        <Breadcrumb.Item onClick={() => history.push('/users')}>客户管理</Breadcrumb.Item>
        <Breadcrumb.Item>客户详情</Breadcrumb.Item>
      </Breadcrumb>

      {activeKey === '1' && <InfoOrder />}
      {activeKey === '2' && <InfoCallback />}
    </React.Fragment>
  )
})