import React from 'react';
import {
  DesktopOutlined, SettingOutlined, CarOutlined,
  MonitorOutlined, ToolOutlined, TeamOutlined,
  TruckOutlined, CarFilled, AuditOutlined
} from '@ant-design/icons';
import homeIndex from './pages/home';
import StockIndex from './pages/stock';
import DetectIndex from './pages/detect';
import PrepareIndex from './pages/prepare';
import UsersIndex from './pages/users';
import UserDetail from './pages/users/detail';
import DeliveryIndex from './pages/delivery';
import InsuranceIndex from './pages/insurance';
import ReturnIndex from './pages/return';
import ReportIndex from './pages/report';
import AccountIndex from './pages/system/account';
import Role from './pages/system/role';
import Password from './pages/system/password';

const routes = [
  { icon: <DesktopOutlined />, title: '首页', auth: 'home', path: '/home', component: homeIndex },
  {
    title: '库存管理',
    icon: <CarOutlined />,
    auth: 'stock',
    path: '/stock',
    component: StockIndex,
    actions: [
      { title: '导入', auth: 'import' },
      { title: '上传车辆照片', auth: 'car_cover' },
      { title: '入库', auth: 'stock' },
      { title: '调拨', auth: 'change_stock' },
      { title: '编辑车辆信息', auth: 'third_car_type' },
      { title: '推送检测', auth: 'detect' },
      { title: '推送整备', auth: 'prepare' },
      { title: '新建租赁订单', auth: 'rental_order' },
      { title: '查看车辆(内部)', auth: 'url_inner' },
    ],
  },
  {
    title: '检测管理',
    icon: <MonitorOutlined />,
    auth: 'detect',
    path: '/detect',
    component: DetectIndex,
    actions: [
      { title: '派单', auth: 'dispatch' },
      { title: '添加第三方检测', auth: 'url' },
      { title: '上传车辆照片', auth: 'car_cover' },
      { title: '查看车辆(内部)', auth: 'url_inner' },
    ],
  },
  {
    title: '整备管理',
    icon: <ToolOutlined />,
    auth: 'prepare',
    path: '/prepare',
    component: PrepareIndex,
    actions: [
      { title: '审核', auth: 'approve' },
      { title: '上传车辆照片', auth: 'car_cover' },
      { title: '查看车辆(内部)', auth: 'url_inner' },
    ],
  },
  {
    title: '客户管理',
    icon: <TeamOutlined />,
    auth: 'users',
    path: '/users',
    component: UsersIndex,
    actions: [
      { title: '编辑', auth: 'edit' },
      { title: '回访', auth: 'callback' },
      // { title: '客户详情', auth: 'detail' },
      { title: '开/关订单', auth: 'handle' },
    ],
  },
  {
    title: '客户详情',
    auth: 'users_detail',
    path: '/user/detail/:id',
    component: UserDetail,
    hide: true,
  },
  {
    title: '交付管理',
    icon: <TruckOutlined />,
    auth: 'delivery',
    path: '/delivery',
    component: DeliveryIndex,
    actions: [
      { title: '交付派单', auth: 'order' },
      { title: '编辑', auth: 'edit' },
      { title: '上传车辆照片', auth: 'car_cover' },
      { title: '查看车辆(内部)', auth: 'url_inner' },
    ],
  },
  {
    title: '收车管理',
    icon: <CarFilled />,
    auth: 'return',
    path: '/return',
    component: ReturnIndex,
    actions: [
      { title: '收车派单', auth: 'order' },
      { title: '编辑', auth: 'edit' },
      { title: '上传车辆照片', auth: 'car_cover' },
      { title: '查看车辆(内部)', auth: 'url_inner' },
    ],
  },
  {
    title: '保险管理',
    icon: <AuditOutlined />,
    auth: 'insurance',
    path: '/insurance',
    component: InsuranceIndex,
    actions: [
      { title: '录入信息', auth: 'add' },
      { title: '上传车辆照片', auth: 'car_cover' },
      { title: '查看车辆(内部)', auth: 'url_inner' },
      { title: '记录违章', auth: 'record' },
    ],
  },
  {
    title: '报表导出',
    icon: <AuditOutlined />,
    auth: 'report',
    path: '/report',
    component: ReportIndex,
    actions: [
      { title: '导出', auth: 'report_btn' },],
  },
  {
    title: '系统管理',
    icon: <SettingOutlined />,
    auth: 'account|role|display_sensitive_data|password',
    child: [
      {
        title: '账户管理',
        auth: 'account',
        actions: [
          { title: '新建', auth: 'add' },
          { title: '编辑', auth: 'edit' },
          { title: '删除', auth: 'delete' },
          { title: '禁用/启用', auth: 'disable' },
        ],
        path: '/system/account',
        component: AccountIndex,
      },
      {
        title: '角色管理',
        auth: 'role',
        actions: [
          { title: '新建', auth: 'add' },
          { title: '编辑', auth: 'edit' },
          { title: '删除', auth: 'delete' },
          { title: '查看脱敏信息', auth: 'display_sensitive_data' },
        ],
        path: '/system/role',
        component: Role,
      },
      {
        title: '修改密码',
        path: '/system/password',
        component: Password,
        hide: true
      },
    ],
  },
]

export default routes