import {makeAutoObservable, action} from 'mobx';
import {http} from 'libs';
import {message} from "antd";

class Store {
  constructor() {
    this.set = (k, v) => {
      if (v && v.nativeEvent) v = v.target.value
      this[k] = v
    }
    this.eSet = k => e => this.set(k, e)
    this.vSet = (k, v) => () => this.set(k, v)
    makeAutoObservable(this)
  }

  record = {};
  fetching = false;


  fetchRecord = (id = 0) => {
    if (!id) {
      message.warn('管理员ID不存在！')
      return
    }
    this.fetching = true
    http.post('/web/', {id: id})
      .then(action('a', res => this.record = res))
      .finally(action('b', () => this.fetching = false))
  };

}

export default new Store()
