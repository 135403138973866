import React from 'react';
import {Button} from 'antd';
import {hasPermission} from 'libs';


export default function AuthButtonV(props) {
  let hidden = props.hidden ?? false;
  const buttonProps = {
    type: 'link',
    style: {padding: 0, display: 'block', width: '100%'},
    ...props
  }

  if (props.auth && !hasPermission(props.auth)) {
    hidden = true;
  }
  return hidden ? null : <Button {...buttonProps}>{props.children}</Button>
}