import React, { useState } from 'react';
import { Modal, Form, Input, message, Image, Select, Cascader } from 'antd';
import { http,data } from 'libs';
import store from './store';
import { ADatePicker, Upload } from "components";
import css from './index.module.less';
import { city } from "../../libs/divisions";

export default function IForm() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const actions = {
    delivery_images: '查看交付照片',
    return_images: '查看收车照片',
    order: '编辑',
    car_cover: '上传车辆照片',
    callback: '回访',
  }

  function handleSubmit() {
    setLoading(true);
    const formData = form.getFieldsValue();
    formData['id'] = store.record.id;
    if(store.formAction === 'callback'){
      formData["is_callback"] = true
    }
    http.patch('/web/lutesi/customer/', formData)
      .then(() => {
        message.success('操作成功');
        store.set('formVisible', false);
        store.fetchRecords()
        store.fetchSummary()
      }, () => setLoading(false))
  }

  return (
    <Modal
      open
      maskClosable={false}
      title={actions[store.formAction]}
      onCancel={store.vSet('formVisible', false)}
      confirmLoading={loading}
      onOk={handleSubmit}>
      <Form form={form} initialValues={store.record} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }}>
        {store.formAction !== 'callback' ? null : (
          <>
            <Form.Item required name="back_time" label="回访时间">
              <ADatePicker
                showTime
                allowClear={false}
                style={{ width: '100%' }}
                format="YYYY-MM-DD HH:mm"
                placeholder="请选择时间" />
            </Form.Item>
            <Form.Item name="back_type"
                       label='回访方式'
                       required
                       rules={[{ required: true, message: '请选择' }]}>
              <Select placeholder="请选择" >
                <Select.Option value='1'>微信</Select.Option>
                <Select.Option value='2'>电话</Select.Option>
                <Select.Option value='3'>短信</Select.Option>
                <Select.Option value='4'>邮件</Select.Option>
                <Select.Option value='5'>其他</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name="next_time" label="预计下次回访时间">
              <ADatePicker
                showTime
                allowClear={false}
                style={{ width: '100%' }}
                format="YYYY-MM-DD HH:mm"
                placeholder="请选择时间" />
            </Form.Item>
            <Form.Item name="name" label="客户名称">
              <Input placeholder="请输入" />
            </Form.Item>
            <Form.Item name="phone" label="客户手机号">
              <Input disabled placeholder="请输入" />
            </Form.Item>
            <Form.Item name="region" label="客户所在地">
              <Cascader options={city}
                        fieldNames={{ label: 'name', value: 'name' }}
                        displayRender={label => label.join(' ')}
                        placeholder="请选择" />
            </Form.Item>
            <Form.Item name="remarks" label="客户备注">
              <Input.TextArea placeholder="请输入" rows={4} />
            </Form.Item>
          </>)}

        {store.formAction !== 'order' ? null : (
          <>
            <Form.Item required name="name" label="客户名称">
              <Input placeholder="请输入" />
            </Form.Item>
            <Form.Item name="remarks" label="备注">
              <Input.TextArea placeholder="请输入" rows={4} />
            </Form.Item>
          </>)}
        {store.formAction !== 'delivery_images' ? null : (
          <div className={css.pic}>
            <Image.PreviewGroup preview>
              {store.record.return_images.map((v, i) => (
                <div style={{ marginRight: 5, marginBottom: 5 }}>
                  <Image key={i} src={v} fallback={data.picFallBack} style={{ borderRadius: 5 }} />
                </div>
              ))}
            </Image.PreviewGroup>
          </div>
        )}
        {store.formAction !== 'return_images' ? null : (
          <div className={css.pic}>
            <Image.PreviewGroup preview>
              {store.record.return_images.map((v, i) => (
                <div style={{ marginRight: 5, marginBottom: 5 }}>
                  <Image key={i} src={v} fallback={data.picFallBack} style={{ borderRadius: 5 }} />
                </div>
              ))}
            </Image.PreviewGroup>
          </div>
        )}
        {
          store.formAction !== 'car_cover' ? null : (
            <Form.Item name="third_car_cover" label='车辆照片' rules={[{ required: true }]}>
              <Upload path="car_cover" />
            </Form.Item>
          )
        }
      </Form>
    </Modal>
  )
}