import React, { useState, useEffect } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import lds from 'lodash';

export function ADatePicker(props) {
  const [value, setValue] = useState()

  useEffect(() => {
    if (props.value) {
      if (value && props.value === value.format(props.format)) return
      setValue(moment(props.value, props.format))
    } else if (value !== undefined) {
      setValue(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value])

  function handleChange(date, dateString) {
    setValue(date)
    props.onChange(dateString)
  }

  return (
    <DatePicker value={value} onChange={handleChange} {...lds.omit(props, ['value', 'onChange'])} />
  )
}

ADatePicker.defaultProps = {
  format: 'YYYY-MM-DD'
}


export function ADateRangePicker(props) {
  const [value, setValue] = useState([])

  useEffect(() => {
    if (props.value) {
      if (value && lds.isEqual(props.value, value.map(x => x.format(props.format)))) return
      setValue(props.value.map(x => moment(x, props.format)))
    } else if (value.length !== 0) {
      setValue([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value])

  function handleChange(dates, dateStrings) {
    setValue(dates)
    props.onChange(dateStrings)
  }

  return (
    <DatePicker.RangePicker  value={value} onChange={handleChange} {...lds.omit(props, ['value', 'onChange'])} />
  )
}

ADateRangePicker.defaultProps = {
  format: 'YYYY-MM-DD HH:mm:ss'
}
