import React from 'react';
import { Layout, Dropdown, Menu } from 'antd';
import { UserOutlined, MenuFoldOutlined, MenuUnfoldOutlined, LogoutOutlined } from '@ant-design/icons';
import { http, history } from 'libs';
import styles from './layout.module.less';

export default function Header(props) {
  function handleLogout() {
    history.push('/');
    http.get('/web/lutesi/account/logout/')
  }

  const menu = (
    <Menu>
      <Menu.Item disabled>
        <UserOutlined/>个人中心
      </Menu.Item>
      <Menu.Divider/>
      <Menu.Item onClick={handleLogout}>
        <LogoutOutlined/>退出登录
      </Menu.Item>
    </Menu>
  )

  return (
    <Layout.Header className={styles.header}>
      <div className={styles.left}>
        <div className={styles.trigger} onClick={props.toggle}>
          {props.collapsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}
        </div>
      </div>
      <div className={styles.right}>
        <Dropdown overlay={menu} style={{background: '#000'}}>
          <span className={styles.action}>{localStorage.getItem('nickname')}</span>
        </Dropdown>
      </div>
    </Layout.Header>
  )
}