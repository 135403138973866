import React from 'react';
import {observer} from 'mobx-react';
import {Modal, Radio, Badge, message} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import {Action, AuthButton, TableCard} from 'components';
import {http} from 'libs';
import store from './store';

export default observer(function () {
  const columns = [{
    title: '序号',
    key: 'series',
    render: (_, __, index) => index + 1
  }, {
    title: '登录名',
    dataIndex: 'username',
  }, {
    title: '账户名称',
    dataIndex: 'nickname',
  }, {
    title: '角色',
    dataIndex: 'role_name',
    render: text => text ?? '无'
  }, {
    title: '状态',
    render: text => text['is_active'] ? <Badge status="success" text="正常"/> : <Badge status="default" text="禁用"/>
  }, {
    title: '注册时间',
    dataIndex: 'created_at',
    hide: true
  }, {
    title: '最近登录',
    dataIndex: 'last_login'
  }, {
    title: '操作',
    align: 'center',
    width: 160,
    render: info => (
      <Action>
        <Action.Button auth="account.disable" onClick={() => handleActive(info)} danger={info['is_active']}>{info['is_active'] ? '禁用' : '启用'}</Action.Button>
        <Action.Button auth="account.edit" onClick={() => store.showForm(info)}>编辑</Action.Button>
        <Action.Button auth="account.delete" onClick={() => handleDelete(info)} danger>删除</Action.Button>
      </Action>
    )
  }]

  function handleActive(info) {
    Modal.confirm({
      title: '操作确认',
      content: `确定要${info['is_active'] ? '禁用' : '启用'}【${info['nickname']}】?`,
      onOk: () => {
        return http.patch(`/web/lutesi/account/`, {id: info.id, is_active: !info['is_active']})
          .then(() => {
            message.success('操作成功');
            store.fetchRecords()
          })
      }
    })
  }

  function handleDelete(info) {
    Modal.confirm({
      title: '删除确认',
      content: `确定要删除【${info['nickname']}】?`,
      onOk: () => {
        return http.delete('/web/lutesi/account/', {params: {id: info.id}})
          .then(() => {
            message.success('删除成功');
            store.fetchRecords()
          })
      }
    })
  }

  return (
    <TableCard
      title="账户列表"
      rowKey="id"
      loading={store.fetching}
      dataSource={store.dataSource}
      columns={columns}
      pagination={{
        current: store.page,
        onChange: (page, pageSize) => {
          store.set('page', page)
          store.set('pageSize', pageSize)
        },
        showSizeChanger: true,
        showLessItems: true,
        hideOnSinglePage: false,
        total: store.pageTotal,
        showTotal: (total, range) => `显示第 ${range[0]} 到第 ${range[1]} 条记录，总共 ${total} 条记录`,
        pageSizeOptions: ['10', '20', '50', '100']
      }}
      onReload={store.fetchRecords}
      actions={[
        <AuthButton auth="account.add" type="primary" icon={<PlusOutlined/>} onClick={() => store.showForm()}>新建账户</AuthButton>,
        <Radio.Group value={store.f_status} onChange={store.eSet('f_status')}>
          <Radio.Button value="">全部</Radio.Button>
          <Radio.Button value="1">正常</Radio.Button>
          <Radio.Button value="2">禁用</Radio.Button>
        </Radio.Group>
      ]}/>
  )
})
