import React, {useEffect, useState} from 'react';
import {Grid, Row, Col, Form, Button} from 'antd';
import {UpOutlined, DownOutlined} from '@ant-design/icons';
import styles from './index.module.less';

const gridCol = {
  xs: 24,
  md: 24,
  lg: 12,
  xl: 8,
  xxl: 6,
}

function Item(props) {
  return (
    <Col {...gridCol}
         span={props.span || 8}
         offset={props.offset || 0}
    >
      <Form.Item label={props.title}>
        {props.children}
      </Form.Item>
    </Col>
  )
}

function SearchForm(props) {
  const screens = Grid.useBreakpoint()
  // 一行显示的数量
  const colNum = 24 / Math.min(...Object.entries(screens).filter(v => !!v[1] && gridCol[v[0]]).map(v => gridCol[v[0]]), 24)
  const [form] = Form.useForm();
  const [expand, setExpand] = useState(props.expand ?? false);
  const [showExpand, setShowExpand] = useState(false);

  useEffect(() => {
    setShowExpand(props.children.length > colNum)
  }, [colNum])

  function handleReset() {
    form.resetFields()
    props?.reset()
  }

  function handleSearch() {
    props?.search()
  }

  return (
    <div className={styles.searchForm} style={props.style}>
      <Form form={form}
            labelWrap
            labelAlign="right"
            labelCol={{span: 7}}
            className={styles.form}>
        <Row gutter={[0, 10]} style={{width: '100%'}}>
          {expand || !props.children.slice ? props.children : props.children.slice(0, colNum)}
        </Row>
      </Form>

      <div className={styles.btns} key={colNum}>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Button className={styles.submitBtn} onClick={handleSearch} type="primary">查询</Button>
          <Button className={styles.resetBtn} onClick={handleReset}>重置</Button>
          {showExpand ? (
            <a onClick={() => setExpand(!expand)}>
              {expand ? <span><UpOutlined/> 折叠</span> : <span><DownOutlined/> 展开</span>}
            </a>
          ) : null}
        </div>
      </div>

    </div>
  )
}

SearchForm.Item = Item
SearchForm.defaultProps = {}

export default SearchForm