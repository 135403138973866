import React from 'react';
import ReactDOM from 'react-dom/client';
import { Router } from 'react-router';
import zhCN from 'antd/es/locale/zh_CN';
import { ConfigProvider } from 'antd';
import { history } from 'libs';
import './index.less';
import App from './App';
import 'moment/locale/zh-cn';
import moment from "moment";
moment.locale('zh-cn');
const root=ReactDOM.createRoot(document.getElementById("root"))

root.render(
  <Router history={history}>
    <ConfigProvider locale={zhCN} getPopupContainer={node => node ? node.parentNode : document.fullscreenElement || document.body}>
      <App/>
    </ConfigProvider>
  </Router>
);