import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Input, Select, Tooltip, Cascader } from 'antd';
import { SearchForm, Breadcrumb } from 'components';
import Table from './Table';
import Form from './Form';
import PushForm from './PushForm';
import AddForm from './AddForm';
import Import from './Import';
import store from './store';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { city } from "libs/divisions1";

export default observer(function () {
  useEffect(() => {
    return store.set("record",{})
  },[])
  useEffect(() => {
    store.fetchRecords()
    store.fetchSummary()
    store.fetchAssign()
    store.fetchUsers()
    store.fetchCars()
  }, [store.page, store.pageSize])

  useEffect(() => {
    if (store.page === 1) {
      store.fetchRecords()
    } else {
      store.set('page', 1)
    }
  }, [store.f_tab])

  return (
    <React.Fragment>
      <Breadcrumb>
        <Breadcrumb.Item>库存管理</Breadcrumb.Item>
      </Breadcrumb>
      <SearchForm reset={store.resetSearch} search={store.handleSearch}>
        <SearchForm.Item title={<Tooltip title="品牌、车系、年款、车型、车牌号、车架号">关键字<QuestionCircleOutlined /></Tooltip>}>
          <Input allowClear
            value={store.f_key}
            onChange={store.eSet('f_key')}
            placeholder="品牌、车系、年款、车型、车牌号、车架号" />
        </SearchForm.Item>
        <SearchForm.Item title="库存状态">
          <Select allowClear value={store.stock_status} onChange={store.eSet('stock_status')} placeholder="请选择">
            <Select.Option value={'1'}>待入库</Select.Option>
            <Select.Option value={'2'}>已入库</Select.Option>
          </Select>
        </SearchForm.Item>
        <SearchForm.Item title="整备状态">
          <Select allowClear value={store.prepare_status} onChange={store.eSet('prepare_status')} placeholder="请选择">
            <Select.Option value={'1'}>待申请</Select.Option>
            <Select.Option value={'2'}>待审核</Select.Option>
            <Select.Option value={'3'}>整备中</Select.Option>
            <Select.Option value={'4'}>已完成</Select.Option>
          </Select>
        </SearchForm.Item>
        <SearchForm.Item title="评估状态">
          <Select allowClear value={store.detect_status} onChange={store.eSet('detect_status')} placeholder="请选择">
            <Select.Option value={'1'}>待派单</Select.Option>
            <Select.Option value={'2'}>待检测</Select.Option>
            <Select.Option value={'3'}>已检测</Select.Option>
          </Select>
        </SearchForm.Item>
        <SearchForm.Item title="租赁状态">
          <Select allowClear value={store.rent_status} onChange={store.eSet('rent_status')} placeholder="请选择">
            <Select.Option value={'1'}>待租赁</Select.Option>
            <Select.Option value={'2'}>已租赁</Select.Option>
          </Select>
        </SearchForm.Item>
        <SearchForm.Item title="交车状态">
          <Select allowClear value={store.delivery_status} onChange={store.eSet('delivery_status')} placeholder="请选择">
            <Select.Option value={'1'}>待交付</Select.Option>
            <Select.Option value={'2'}>已交付</Select.Option>
          </Select>
        </SearchForm.Item>
        <SearchForm.Item title="所在城市">
          <Cascader options={city}
            changeOnSelect
            value={store.location}
            onChange={store.eSet('location')}
            fieldNames={{ label: 'name', value: 'name' }}
            displayRender={label => label.join(' ')}
            placeholder="请选择" />
        </SearchForm.Item>
        <SearchForm.Item title="所属仓库">
          <Select allowClear value={store.stock} onChange={store.eSet('stock')} placeholder="请选择">
            <Select.Option value='路特斯-北京仓'>路特斯-北京仓</Select.Option>
            <Select.Option value='路特斯-上海仓'>路特斯-上海仓</Select.Option>
            <Select.Option value='路特斯-武汉仓'>路特斯-武汉仓</Select.Option>
            <Select.Option value='路特斯-杭州仓'>路特斯-杭州仓</Select.Option>
            <Select.Option value='路特斯-成都仓'>路特斯-成都仓</Select.Option>
            <Select.Option value='路特斯-海口仓'>路特斯-海口仓</Select.Option>
          </Select>
        </SearchForm.Item>
      </SearchForm>
      <Table />
      {store.formVisible && <Form />}
      {store.pushformVisible && <PushForm />}
      {store.addformVisible && <AddForm />}
      {store.importVisible && <Import />}
    </React.Fragment>
  )
})