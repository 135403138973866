import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {Modal, Tag, Space, message} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import {Action, AuthButton, TableCard} from 'components';
import {http} from 'libs';
import store from './store';
import routes from "routes";


export default observer(function () {
  const [auths, setAuths] = useState([]);

  useEffect(() => {
    setAuths(getAuthData())
  }, [])

  function getAuthData() {
    let arr = []
    routes.map(route => {
      route.auth && arr.push({auth: route.auth, title: route.title})
      if (route.child) {
        route.child.map(page => {
          page.auth && arr.push({auth: page.auth, title: page.title})
        })
      }

    })
    return arr
  }

  const columns = [{
    title: '序号',
    key: 'series',
    width: 60,
    render: (_, __, index) => index + 1
  }, {
    title: '角色',
    dataIndex: 'name',
    width: 110
  }, {
    title: '权限',
    dataIndex: 'permissions',
    render: permissions => <Space wrap>
      {
        auths.map((v, i) => {
            if (permissions.includes(v.auth)) return <Tag key={i} color="blue">{v.title}</Tag>
          }
        )
      }
    </Space>
  }, {
    title: '操作',
    align: 'center',
    width: 120,
    render: info => (
      <Action>
        <Action.Button auth="role.edit" onClick={() => store.showForm(info)}>编辑</Action.Button>
        <Action.Button auth="role.delete" onClick={() => handleDelete(info)} danger>删除</Action.Button>
      </Action>
    )
  }]

  function handleDelete(info) {
    Modal.confirm({
      title: '删除确认',
      content: `确定要删除【${info['name']}】?`,
      onOk: () => {
        return http.delete('/web/lutesi/role/', {params: {id: info.id}})
          .then(() => {
            message.success('删除成功');
            store.fetchRecords()
          })
      }
    })
  }

  return (
    <TableCard
      title="角色列表"
      rowKey="id"
      loading={store.fetching}
      dataSource={store.dataSource}
      columns={columns}
      pagination={{
        current: store.page,
        onChange: (page, pageSize) => {
          store.set('page', page)
          store.set('pageSize', pageSize)
        },
        showSizeChanger: true,
        showLessItems: true,
        hideOnSinglePage: false,
        total: store.pageTotal,
        showTotal: (total, range) => `显示第 ${range[0]} 到第 ${range[1]} 条记录，总共 ${total} 条记录`,
        pageSizeOptions: ['10', '20', '50', '100']
      }}
      onReload={store.fetchRecords}
      actions={[
        <AuthButton auth="role.add" type="primary" icon={<PlusOutlined/>} onClick={() => store.showForm()}>新建</AuthButton>,
      ]}/>
  )
})
