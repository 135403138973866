import React from 'react';
import { observer } from 'mobx-react';
import { Radio, Image, Typography, Tag, Tooltip } from 'antd';
import { CloudUploadOutlined, PlusOutlined } from '@ant-design/icons';
import { TableCard, AuthDiv, AuthButtonV, AuthButton } from 'components';
import { data, } from 'libs';
import store from './store';
import moment from 'moment';


export default observer(function () {
  const maxWidth = {
    wordBreak: 'break-all',
    maxWidth: '12vw'
  }

  const tabs = [
    { value: "1", label: `待入库(${store.summary["kc_drk"] || 0})` },
    { value: "2", label: `在库(${store.summary["kc_rk"] || 0})` },
    { value: "3", label: `待交付(${store.summary["kc_djf"] || 0})` },
    { value: "4", label: `租赁中(${store.summary["kc_zlz"] || 0})` },
    { value: "5", label: `已还车(${store.summary["kc_yhc"] || 0})` },
    { value: "", label: `全部(${store.summary["kc_qb"] || 0})` },
  ]


  const columns = [
    {
    title: '车辆照片',
    dataIndex: 'third_car_cover',
    render: (value, info) => value ?
      <Image src={info.third_car_cover} fallback={data.picFallBack} width={140} style={{ maxHeight: 140 }} />
      : (
        <AuthDiv auth="stock.car_cover" onClick={() => store.showForm(info, 'car_cover')}>
          <Typography.Link>上传</Typography.Link>
        </AuthDiv>
      )
  }, {
    title: '车辆详情',
    render: info => <div style={maxWidth}>
      <span><div style={{ color: '#000000' }}>{info.car_full_model}</div></span>
      <div style={{ color: '#0086FF' }}>{info.car_plate_number} {info.car_plate_number ? '/' : null} {info.car_vin}</div>
      <div style={{ color: '#717171' }}>{info.lotus_plate_type}</div>
      <div>{info.third_car_status ? <span><Tag color="#000000" style={{ marginBottom: 4 }}>{info.third_car_status}</Tag></span> : null} {info.insurance_status === '已投保' ? <span><Tag color="#0086FF">{info.insurance_status}</Tag></span>
        : <span><Tag color="#FF2600">{info.insurance_status}</Tag></span>}</div>
    </div>
  }, {
    title: '库存状态',
    render: info => <div style={maxWidth}>
      <div>{info.stock_status !== '1' ? info.stock : info.pre_stock}</div>
      <div style={{ color: '#717171' }}>永达库编号：{info.car_erp_sn}</div>
      <div style={{ color: '#717171' }}>市场：{info.market}</div>
      <div style={{ color: '#717171' }}>资产状态：{info.asset_status}</div>
      <div style={{ color: '#717171' }}>路特斯车辆编号：{info.lotus_sn}</div>
      {info.stock_status !== '1' && <div style={{ color: '#717171' }}>入库：{info.stock_time}</div>}
      {info.stock_status !== '1' && <div style={{ color: '#717171' }}>库存：{info.stock_days}</div>}
    </div>
  }, {
    title: '车辆属性',
    render: info => <div style={maxWidth}>
      <div>里程：{info.car_mileage}</div>
      <div style={{ color: '#717171' }}>颜色：{info.car_color}</div>
      <div style={{ color: '#717171' }}>座位：{info.car_seats}</div>
      <div style={{ color: '#717171' }}>电机：{info.car_motor}</div>
    </div>
  }, {
    title: '车辆用途',
    render: info => <div style={maxWidth}>
      <div>车辆类型：{info.third_car_type}</div>
      <div style={{ color: '#717171' }}>展车阶段：{info.car_show_grade}</div>
      <div style={{ color: '#717171' }}>车辆用途：{info.car_use}</div>
      <div style={{ color: '#717171' }}>活动标签：{info.activity_label}</div>
    </div>
  }, {
    title: '客户信息',
    render: info => <div style={maxWidth}>
      <div>用车部门：{info.use_by_department}</div>
      <div style={{ color: '#717171' }}>用车人：{info.use_by_name}</div>
      <div style={{ color: '#717171' }}>借车日期：{info.lotus_delivery_time ? moment(info.lotus_delivery_time).format('YYYY-MM-DD') : null}</div>
      <div style={{ color: '#717171' }}>拟还车日期：{info.lotus_return_time ? moment(info.lotus_return_time).format('YYYY-MM-DD') : null}</div>
    </div>
  },
  //   {
  //   title: '保险信息',
  //   render: info => <div style={maxWidth}>
  //     <span><div style={{ color: '#000000' }}>交强：{info.traffic.status_alias}</div></span>
  //     {/*{info.traffic.expired_at ? <div style={{ color: '#717171' }}>{info.traffic.expired_at}到期</div> : null}*/}
  //     {info.traffic.left_days ? <div style={{ color: '#717171' }}>剩余{info.traffic.left_days}天</div> : null}
  //     <span><div style={{ color: '#000000' }}>商业：{info.bussiness.status_alias}</div></span>
  //     {/*{info.bussiness.expired_at ? <div style={{ color: '#717171' }}>{info.bussiness.expired_at}到期</div> : null}*/}
  //     {info.bussiness.left_days ? <div style={{ color: '#717171' }}>剩余{info.bussiness.left_days}天</div> : null}
  //
  //   </div>
  // },
    {
    title: '城市地址',
    render: info => <div>
      <Tooltip
        title={
          <>
            <p>{info.region[0]} {info.region[1]} {info.region[2]}</p>
            <p>{info.detail}</p>
          </>
        }>
        {info.region[0]}<br />
        {info.region[1]}<br />
        {info.region[2]}
      </Tooltip>
    </div>
  }, {
    title: '订阅状态',
    render: info => <div style={maxWidth}>
      <div>{info.prepare_status_alias} {info.prepare_time ? <span style={{ color: '#717171' }}>{info.prepare_time}</span> : null}</div>
      <div>{info.detect_status_alias} {info.detect_time ? <span style={{ color: '#717171' }}>{info.detect_time}</span> : null}</div>
      <div>{info.rent_status_alias} {info.rent_time ? <span style={{ color: '#717171' }}>{info.rent_time}</span> : null}</div>
      <div>{info.delivery_status_alias} {info.delivery_at ? <span style={{ color: '#717171' }}>{info.delivery_at}</span> : null}</div>
      <div>{info.return_status_alias} {info.return_at ? <span style={{ color: '#717171' }}>{info.return_at}</span> : null}</div>
    </div>
  }, {
    title: '操作',
    fixed: 'right',
    align: 'center',
    render: info => <>
      <AuthButtonV auth="stock.detect"
        onClick={() => store.showPushForm(info, 'detect')}

      >
        推送检测
      </AuthButtonV>
      <AuthButtonV auth="stock.prepare"
        onClick={() => store.showPushForm(info, 'prepare')}

      >
        推送整备
      </AuthButtonV>
      <AuthButtonV auth="stock.stock"
        onClick={() => store.showForm(info, 'stock')}
        hidden={info.stock_status !== '1'}
      >
        入库
      </AuthButtonV>
      <AuthButtonV auth="stock.change_stock"
        onClick={() => store.showForm(info, 'change_stock')}

      >
        调拨
      </AuthButtonV>
      <AuthButtonV auth="stock.rental_order"
                   onClick={() => store.showAddForm(info)}>
        新建租赁订单
      </AuthButtonV>
      <AuthButtonV auth="stock.third_car_type"
        onClick={() => store.showForm(info, 'third_car_type')}>
        编辑车辆信息
      </AuthButtonV>
      <AuthButtonV auth="stock.url_inner"
        href={'/pages/carDetail/index.html?id=' + info.car_sn}
        target="_blank">
        查看车辆（内部版)
      </AuthButtonV>
      {/*<AuthButtonV auth="stock.url_out"*/}
      {/*  href={'/pages/carOutDetail/index.html?id=' + info.car_sn}*/}
      {/*  target="_blank">*/}
      {/*  查看车辆（外部版)*/}
      {/*</AuthButtonV>*/}
    </>
  },]


  return (
    <TableCard
      title="库存列表"
      rowKey="id"
      loading={store.fetching}
      dataSource={store.records}
      columns={columns}
      pagination={{
        current: store.page,
        onChange: (page, pageSize) => {
          store.set('page', page)
          store.set('pageSize', pageSize)
        },
        showSizeChanger: true,
        showLessItems: true,
        hideOnSinglePage: false,
        total: store.pageTotal,
        showTotal: (total, range) => `显示第 ${range[0]} 到第 ${range[1]} 条记录，总共 ${total} 条记录`,
        pageSizeOptions: ['10', '20', '50', '100']
      }}
      onReload={store.fetchRecords}
      actions={[
        <Radio.Group optionType={"button"}
          options={tabs}
          value={store.f_tab}
          onChange={store.eSet('f_tab')}
        />,
        <AuthButton auth="stock.rental_order" type="primary" icon={<PlusOutlined />} onClick={() => store.showAddForm()}>新建租赁订单</AuthButton>,
        <AuthButton auth="stock.import" type="primary" icon={<CloudUploadOutlined />} onClick={() => store.showImport()}>导入</AuthButton>,
      ]} />
  )
})
