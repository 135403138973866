import React, {useState} from 'react';
import {Button, Input, Form, Card, Spin, message} from 'antd';
import {http, history} from 'libs';
import {observer} from "mobx-react";
import store from "./store";
import {Breadcrumb} from "components";

import styles from "./index.module.less"

export default observer(function () {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false);

  function handleSubmit(formData) {
    setLoading(true);

    http.put('/web/lutesi/account/', formData)
        .then(res => {
          message.success('密码修改成功，请重新登录！')
          localStorage.setItem('token', '');
          history.push('/');
        }, () => setLoading(false))
  }

  const initData = store.record ? {
    nickname: localStorage.getItem('nickname'),
    oldpassword: '',
    newpassword: '',
    conpassword: '',
  } : {}

  return (
    <React.Fragment>
      <Breadcrumb hideTitle={true}>
        <Breadcrumb.Item>设置</Breadcrumb.Item>
        <Breadcrumb.Item>修改密码</Breadcrumb.Item>
      </Breadcrumb>

      <Card>
        {
          store.record ? (

            <Form form={form}
                  onFinish={handleSubmit}
                  initialValues={initData}
                  autoComplete='off'
                  labelCol={{span: 6}} wrapperCol={{span: 18}}
                  className={styles.commonForm}>
              <Form.Item name="old_password"
                         label='原密码'
                         hasFeedback
                         rules={[
                           {
                             required: true,
                             message: '请输入原密码',
                           },
                         ]}>
                <Input.Password autoComplete="new-password" placeholder='请输入原密码'/>
              </Form.Item>
              <div>
                <Input type='password' style={{position: 'absolute', top: '-999px'}}/>
              </div>
              <Form.Item name="new_password"
                         label='新密码'
                         hasFeedback
                         rules={[
                           {
                             required: true,
                             message: '请输入新密码',
                           },
                         ]}>
                <Input.Password autoComplete="new-password" placeholder='请输入新密码'/>
              </Form.Item>
              <Form.Item name="con_password"
                         label='再次输入新密码'
                         dependencies={['new_password']}
                         hasFeedback
                         rules={[
                           {
                             required: true,
                             message: '请再次输入新密码',
                           },
                           ({getFieldValue}) => ({
                             validator(_, value) {
                               if (!value || getFieldValue('new_password') === value) {
                                 return Promise.resolve();
                               }

                               return Promise.reject(new Error('两次输入密码不一致'));
                             },
                           }),
                         ]}>
                <Input.Password autoComplete="new-password" placeholder='请再次输入新密码'/>
              </Form.Item>
              <Form.Item label=" " colon={false}>
                <Button block size="large" type="primary" htmlType="submit" loading={loading}>确认</Button>
              </Form.Item>
            </Form>
          ) : (
            <div style={{textAlign: "center"}}><Spin/></div>
          )
        }

      </Card>

    </React.Fragment>
  )
})
