import React, { useEffect, useState } from 'react';
import { Modal, Form, message, InputNumber, Input, Radio, Table, Popconfirm } from 'antd';
import { deepCopy, http } from 'libs';
import { Upload, ADatePicker } from "components";
import store from './store';
import { observer } from 'mobx-react';

export default observer(function IForm() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [record, setRecord] = useState({})
  const [showEdit, setShowEdit] = useState(false);
  const actions = {
    add: '信息录入',
    car_cover: '上传车辆照片',
    record: '违章记录',
    more: '全部违章记录',
  }


  function handleSubmit() {
    if(store.formAction === 'more') return store.set('formVisible', false);
    setLoading(true);
    const formData = form.getFieldsValue();
    if(store.formAction !== 'record') formData['id'] = store.record.id; //新建编辑记录不传ID
    store.formAction === 'record' ? //新建违章记录
      http.post(`/web/lutesi/violation/${store.record.id}/`, formData)
        .then(() => {
          message.success('操作成功');
          store.set('formVisible', false);
          store.fetchRecords()
          store.fetchSummary()
        }, () => setLoading(false))
      :
      http.patch('/web/lutesi/insurance/', formData)
        .then(() => {
          message.success('操作成功');
          store.set('formVisible', false);
          store.fetchRecords()
          store.fetchSummary()
        }, () => setLoading(false))
  }

  function handleSave(info){
    http.post(`/web/lutesi/violation/${store.record.id}/`, {id:info.id, content:info.content})
      .then(() => {
        message.success('操作成功');
        setShowEdit(false)
        store.fetchRecords()
        store.fetchSummary()
      }, () => setLoading(false))
  }

  function handleDelete(info) {
    http.delete(`/web/lutesi/violation/${store.record.id}/`, {data:{id:info.id, content:info.content}})
      .then(() => {
        message.success('操作成功');
        setShowEdit(false)
        store.fetchRecords()
        store.fetchSummary()
        const newData = records.filter((item) => item.id !== info.id);
        setRecords(newData);
      }, () => setLoading(false))
  }

  function handleEdit(info){
    setShowEdit(true)
    setRecord(info)
  }

  const columns = [
    {
      title: '违章',
      dataIndex: 'content',
      width: '80%',
      editable: true,
      render:(value, info, index) =>
        (showEdit && record.id === info.id ?
          <Input.TextArea rows={4} value={records[index]?.['content']}
                               onChange={val => handleChange('content', val.target.value, index)}
        /> : <span>{records[index]?.['content']}</span>)

    },
    {
      title: '操作',
      dataIndex: 'operation',
      render: (_, info) =>
        records.length >= 1 ? (<div style={{display:'flex', justifyContent:'space-between'}}>
            {showEdit && record.id === info.id ? <div style={{color:"#1890FF", cursor: 'pointer'}} onClick={() =>handleSave(info)}>保存</div> :
            <div style={{color:"#1890FF", cursor: 'pointer'}} onClick={() => handleEdit(info)}>编辑</div>}
            <Popconfirm title="确定要删除这条违章记录吗?" onConfirm={() => handleDelete(info)}>
              <a>删除</a>
            </Popconfirm>
          </div>
        ) : null,
    },
  ];

  useEffect(() => {
    setRecords(store.record.violation ?? [])
  }, [])

  function handleChange(name, value, index) {
    let arr = deepCopy(records)
    arr[index][name] = value
    setRecords([...arr])
  }

  return (
    <Modal
      open
      maskClosable={false}
      title={actions[store.formAction]}
      onCancel={store.vSet('formVisible', false)}
      confirmLoading={loading}
      onOk={form.submit}>
      <Form form={form}
        onFinish={handleSubmit}
        initialValues={store.record}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 14 }}>
        {
          store.formAction !== 'car_cover' ? null : (
            <Form.Item name="third_car_cover" label='车辆照片' rules={[{ required: true }]}>
              <Upload path="car_cover" />
            </Form.Item>
          )
        }
        {
          store.formAction !== 'add' ? null : (
            <>
              <Form.Item name="name"
                label='保险公司'
                rules={[{ required: true }]}>
                <Input placeholder="请输入" />
              </Form.Item>
              <Form.Item name="type"
                label='保险种类'
                rules={[{ required: true, message: '请选择' }]}>
                <Radio.Group>
                  <Radio value="1">交强险</Radio>
                  <Radio value="2">商业险</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item name="url" label='保险凭证' >
                <Upload path="car_insurance" />
              </Form.Item>
              <Form.Item required name="expired_at" label="保险到期日期">
                <ADatePicker
                  allowClear={false}
                  format="YYYY-MM-DD"
                  placeholder="请选择日期" />
              </Form.Item>
              <Form.Item name="money" label="保险金额" >
                <InputNumber min={0} precision={2} addonAfter="元" />
              </Form.Item>
              <Form.Item name="remarks" label="备注">
                <Input.TextArea placeholder="请输入" rows={4} />
              </Form.Item>
            </>
          )
        }
        {
          store.formAction !== 'record' ? null : (
            <>
              <Form.Item name="content" label="违章原因">
                <Input.TextArea placeholder="请输入" rows={4} />
              </Form.Item>
            </>
          )
        }
        {
          store.formAction !== 'more' ? null : (
            <>
              <Table
                rowKey="id"
                bordered
                dataSource={records}
                columns={columns}
                pagination={false}
              />
            </>
          )
        }
      </Form>
    </Modal>
  )
})