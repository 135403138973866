import React, { useState, useEffect } from 'react';
import { Card, Table } from 'antd';
import store from './store';
import { observer } from "mobx-react";

export default observer( function IForm() {
  const maxWidth = {
    wordBreak: 'break-all',
    maxWidth: '16vw'
  }
  useEffect(() => {
    setRecords(store.record.callbacks)
  }, [store.record])

  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);


  const columns = [
    // {
    //   title: '客户名称',
    //   dataIndex: 'name'
    // }, {
    //   title: '手机号',
    //   dataIndex: 'phone'
    // }, {
    //   title: '客户所在地',
    //   render: info => <>{info.region.join("")}</>
    // },
    {
      title: '回访时间',
      dataIndex: 'back_time'
    }, {
      title: '回访方式',
      dataIndex: 'back_type_alias'
    }, {
      title: '预计下次回访时间',
      dataIndex: 'back_time'
    }, {
      title: '回访人',
      dataIndex: 'created_by'
    }, {
      title: '客户备注',
      render: info => <div style={maxWidth}>{info.remarks}</div>,
      // dataIndex: 'remarks'
    }
  ]

  return (
    <Card>
      <Table dataSource={records}
        rowKey="id"
        columns={columns}
        loading={loading}
        pagination={false} />
    </Card>
  )
})