import React from 'react';
import { observer } from 'mobx-react';
import { Radio, Image, Typography, Tag } from 'antd';
import { TableCard, AuthDiv, AuthButtonV } from 'components';
import { data, } from 'libs';
import store from './store';


export default observer(function () {
  const maxWidth = {
    wordBreak: 'break-all',
    maxWidth: '12vw'
  }

  const tabs = [
    { value: "1", label: `待派单(${store.summary["jc_dpd"] || 0})` },
    { value: "2", label: `待检测(${store.summary["jc_djc"] || 0})` },
    { value: "3", label: `已检测(${store.summary["jc_yjc"] || 0})` },
    { value: "", label: `全部(${store.summary["jc_qb"] || 0})` },
  ]

  const columns = [{
    title: '整备订单号',
    render: info => <div style={maxWidth}>
      <div>{info.sn}</div>
      <div style={{ color: '#717171' }}>{info.created_at}</div>
      <div style={{ color: '#717171' }}>{info.created_by_name}</div>
      <div>{info.type === '1' ? <span><Tag color='#000000'>{info.type_alias}</Tag></span> : <Tag color='#D2D2D2'><span style={{ color: '#000000' }}>{info.type_alias}</span></Tag>}</div>
    </div>
  }, {
    title: '车辆照片',
    dataIndex: 'third_car_cover',
    render: (value, info) => value ?
      <Image src={info.third_car_cover} fallback={data.picFallBack} width={140} style={{ maxHeight: 140 }} />
      : (
        <AuthDiv auth="detect.car_cover" onClick={() => store.showForm(info, 'car_cover')}>
          <Typography.Link>上传</Typography.Link>
        </AuthDiv>
      )
  }, {
    title: '车辆详情',
    render: info => <div style={maxWidth}>
      <span><div style={{ color: '#000000' }}>{info.car_full_model}</div></span>
      <div style={{ color: '#717171' }}>{info.car_plate_number} {info.car_color}</div>
      <div style={{ color: '#717171' }}>{info.car_mileage}</div>
      <div style={{ color: '#717171' }}>{info.car_vin}</div>
      <div>{info.third_car_type ? <span><Tag color="#000000">{info.third_car_type}</Tag></span> : null}</div>
    </div>
  }, {
    title: '检测状态',
    render: info => <div style={maxWidth}>
      <div>{info.status_alias}</div>
    </div>
  }, {
    title: '派单信息',
    render: info => <div style={maxWidth}>
      {info.assign_at ? <div>派单时间：{info.assign_at}</div> : null}
      {info.assign_by_name ? <div style={{ color: '#717171' }}>检测师 {info.assign_by_name}</div> : null}
    </div>
  }, {
    title: '检测报告',
    render: info => <div style={maxWidth}>
      {info.detect_url ? <Typography.Link href={info.detect_url} target="_blank">查看检测报告</Typography.Link> : null}<br />
      {info.detect_at ? <div>检测日期：{info.detect_at}</div> : null}
      {info.third_detect_url ? <Typography.Link href={info.third_detect_url} target="_blank">第三方检测报告</Typography.Link> : null}
    </div>
  }, {
    title: '操作',
    fixed: 'right',
    align: 'center',
    render: info => <>
      <AuthButtonV auth="detect.dispatch"
                   onClick={() => store.showForm(info, 'dispatch')}
                   hidden={info.status !== '1'}
      >
        派单
      </AuthButtonV>
      <AuthButtonV auth="detect.url"
        onClick={() => store.showForm(info, 'url')}
        hidden={info.status !== '3'}
      >
        第三方检测
      </AuthButtonV>
      <AuthButtonV auth="detect.url_inner"
        href={'/pages/carDetail/index.html?id=' + info.car_sn}
        target="_blank">
        查看车辆（内部版)
      </AuthButtonV>
      {/*<AuthButtonV auth="detect.url_out"*/}
      {/*  href={'/pages/carOutDetail/index.html?id=' + info.car_sn}*/}
      {/*  target="_blank">*/}
      {/*  查看车辆（外部版)*/}
      {/*</AuthButtonV>*/}
    </>
  },]



  return (
    <TableCard
      title="检测列表"
      rowKey="id"
      loading={store.fetching}
      dataSource={store.records}
      columns={columns}
      pagination={{
        current: store.page,
        onChange: (page, pageSize) => {
          store.set('page', page)
          store.set('pageSize', pageSize)
        },
        showSizeChanger: true,
        showLessItems: true,
        hideOnSinglePage: false,
        total: store.pageTotal,
        showTotal: (total, range) => `显示第 ${range[0]} 到第 ${range[1]} 条记录，总共 ${total} 条记录`,
        pageSizeOptions: ['10', '20', '50', '100']
      }}
      onReload={store.fetchRecords}
      actions={[
        <Radio.Group optionType={"button"}
          options={tabs}
          value={store.f_tab}
          onChange={store.eSet('f_tab')}
        />
      ]} />
  )
})
