import { makeAutoObservable } from 'mobx';
import { http } from 'libs';

class Store {
  constructor() {
    this.set = (k, v) => {
      if (v && v.nativeEvent) v = v.target.value
      this[k] = v
    }
    this.eSet = k => e => this.set(k, e)
    this.vSet = (k, v) => () => this.set(k, v)
    makeAutoObservable(this)
  }

  records = [];
  summary = {};
  record = {};
  page = 1;
  pageSize = 10;
  pageTotal = 0;
  fetching = false;
  formVisible = false;
  addformVisible = false;
  formAction = '';

  f_key = null;
  f_status = null;
  f_tab = '';
  delivery_time = null;
  return_time = null;
  f_name = null;
  f_phone = null


  fetchRecords = () => {
    const query = {
      delivery_time: this.delivery_time?.length ? this.delivery_time.map(v => v.format('YYYY-MM-DD')) : '',
      return_time: this.return_time?.length ? this.return_time.map(v => v.format('YYYY-MM-DD')) : '',
      tab: this.f_tab,
      name: this.f_name,
      phone: this.f_phone,
    }
    this.fetching = true;
    http.post('/web/lutesi/customer/', { ...query, page: this.page, page_size: this.pageSize })
      .then(res => {
        this.set('records', res.results);
        this.set('pageTotal', res.count)
      })
      .then(() => this.fetchSummary())
      .finally(() => this.set('fetching', false))
  };

  fetchSummary = () => {
    http.get('/web/lutesi/summary/')
      .then(res => this.set('summary', res))
  }


  showForm = (info, action) => {
    this.record = info ?? {};
    this.formAction = action ?? ''
    this.formVisible = true
  }

  showAddForm = (info) => {
    this.record = info ?? {};
    this.addformVisible = true
  }

  resetSearch = () => {
    this.f_key = null;
    this.f_assign = null;
    this.location = null;
    this.delivery_time = null;
    this.return_time = null;
    this.f_tab = null;
    this.f_name = null;
    this.f_phone = null;
  }

  pageChange = (page, size) => {
    this.records = []
    this.page = page;
    this.pageSize = size;
    this.fetchRecords()
  }

  handleSearch = () => {
    this.page = 1;
    this.records = []
    this.fetchRecords()
  }

}

export default new Store()