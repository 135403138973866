import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Select, message, Radio } from 'antd';
import { http, includes } from 'libs';
import store from './store';
import roleStore from '../role/store';


export default function IForm() {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (store.record.market) {
      form.setFieldValue('market', store.record.market)
    }else{
      form.setFieldValue('market', '中国')
    }
  }, [store.record])

  function handleSubmit() {
    const formData = form.getFieldsValue();
    if(store.record.id){
      formData['id'] = store.record.id
    }
    setLoading(true);
    http.post('/web/lutesi/account/', formData)
      .then(() => {
        message.success('操作成功');
        store.set('formVisible', false);
        store.fetchRecords()
      }, () => setLoading(false))
  }

  return (
    <Modal
      open
      //width={600}
      maskClosable={false}
      title={store.record.id ? '编辑账户' : '新建账户'}
      onCancel={store.vSet('formVisible', false)}
      confirmLoading={loading}
      onOk={form.submit}>
      <Form form={form}
        onFinish={handleSubmit}
        initialValues={store.record}
        layout="vertical"
        labelWrap
        wrapperCol={{ span: 23 }}>
        <Form.Item name="username" label="登录名" rules={[{ required: true }]}>
          <Input autoComplete="off" placeholder="请输入登录名" />
        </Form.Item>
        <Form.Item name="nickname" label="姓名" rules={[{ required: true }]}>
          <Input autoComplete="off" placeholder="请输入姓名" />
        </Form.Item>
        <Form.Item name="password" label="密码">
          <Input type="password" autoComplete="new-password" placeholder="请输入密码" />
        </Form.Item>
        <Form.Item name="role_ids" label="所属角色" rules={[{ required: true }]}>
          <Select placeholder="请选择角色"
            allowClear
            filterOption={(value, option) => includes(option.children, value)}
            mode='multiple'>
            {
              roleStore.records.map((v, i) => (
                <Select.Option key={i} value={v.id}>{v.name}</Select.Option>
              ))
            }
          </Select>
        </Form.Item>
        <Form.Item name="market"
                   label='可管理市场'
                   rules={[{ required: true, message: '请选择' }]}>
          <Radio.Group>
            <Radio value="全部">全部</Radio>
            <Radio value="中国">中国</Radio>
            <Radio value="海外">海外</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  )
}
