import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Input, Tooltip, DatePicker } from 'antd';
import { SearchForm, Breadcrumb } from 'components';
import Table from './Table';
import Form from './Form';
import store from './store';
import AddForm from './AddForm';
import { QuestionCircleOutlined } from '@ant-design/icons';

export default observer(function () {
  useEffect(() => {
    store.fetchRecords()
    store.fetchSummary()
    store.fetchAssign()
    store.fetchUsers()
    store.fetchCars()
  }, [store.page, store.pageSize])

  useEffect(() => {
    if (store.page === 1) {
      store.fetchRecords()
    } else {
      store.set('page', 1)
    }
  }, [store.f_tab])

  return (
    <React.Fragment>
      <Breadcrumb>
        <Breadcrumb.Item>收车管理</Breadcrumb.Item>
      </Breadcrumb>
      <SearchForm reset={store.resetSearch} search={store.handleSearch}>
        <SearchForm.Item title={<Tooltip title="品牌、车系、年款、车型、车牌号、车架号">关键字<QuestionCircleOutlined /></Tooltip>}>
          <Input allowClear
            value={store.f_key}
            onChange={store.eSet('f_key')}
            placeholder="品牌、车系、年款、车型、车牌号、车架号" />
        </SearchForm.Item>
        <SearchForm.Item title="客户名称">
          <Input allowClear
            value={store.f_name}
            onChange={store.eSet('f_name')}
            placeholder="请输入" />
        </SearchForm.Item>
        <SearchForm.Item title="客户手机号">
          <Input allowClear
            value={store.f_phone}
            onChange={store.eSet('f_phone')}
            placeholder="请输入" />
        </SearchForm.Item>
        <SearchForm.Item title="交付日期">
          <DatePicker.RangePicker value={store.delivery_time}
            onChange={store.eSet('delivery_time')}
            style={{ width: '100%' }} />
        </SearchForm.Item>
        <SearchForm.Item title="还车日期">
          <DatePicker.RangePicker value={store.return_time}
            onChange={store.eSet('return_time')}
            style={{ width: '100%' }} />
        </SearchForm.Item>
        <SearchForm.Item title="下单人">
          <Input allowClear
                 value={store.created_by_name}
                 onChange={store.eSet('created_by_name')}
                 placeholder="请输入" />
        </SearchForm.Item>
      </SearchForm>
      <Table />
      {store.formVisible && <Form />}
      {store.addformVisible && <AddForm />}
    </React.Fragment>
  )
})