import {makeAutoObservable} from 'mobx';
import {http, includes} from 'libs';

class Store {
  constructor() {
    this.set = (k, v) => {
      if (v && v.nativeEvent) v = v.target.value
      this[k] = v
    }
    this.eSet = k => e => this.set(k, e)
    this.vSet = (k, v) => () => this.set(k, v)
    makeAutoObservable(this)
  }

  userId = 0;
  record = {};
  fetching = false;

  fetchRecords = (id) => {
    this.fetching = true;
    http.get(`/web/lutesi/customer/?id=${id}`)
      .then(res => this.set('record', res))
      .finally(() => this.set('fetching', false))
  };

}

export default new Store()