import React, {useState} from 'react';
import {Form, Input, Button, Image, Modal} from 'antd';
import {CopyrightOutlined} from '@ant-design/icons';
import {http, history, hasPermission} from 'libs';
import Routes from 'routes';
import styles from './login.module.less';

export default function Login() {
  const [loading, setLoading] = useState(false);

  function handleSubmit(formData) {
    setLoading(true);
    http.post('/web/lutesi/account/login/', formData)
      .then(data => {
        localStorage.setItem('token', data['access_token']);
        localStorage.setItem('nickname', data['nickname']);
        localStorage.setItem('is_supper', data['is_supper']);
        localStorage.setItem('permissions', data['permissions']);
        let path = getFirstAuthRoutePath(data)
        if (history.location.state && history.location.state['from']) {
          history.replace(history.location.state['from'])
        } else if (path) {
          history.replace(path)
        } else {
          Modal.warning('该账号无访问权限，请更换账号登录！')
        }
      }, () => setLoading(false))
  }

  function getFirstAuthRoutePath(user) {
    let url = ''

    if (user.is_supper) {
      url = '/home'
    } else if (user.permissions?.length) {
      Routes.forEach(v => {
        if (url) return
        if (hasPermission(v.auth)) {
          if (v.path) {
            url = v.path
          } else {
            v.child.forEach(c => {
              if (url) return
              if (hasPermission(c.auth)) {
                url = c.path
              }
            })
          }
        }
      })

    }

    return url;
  }

  return (
    <div className={styles.container}>
      <div className={styles.logo}/>
      <div className={styles.formCard}>
        <h2>欢迎登录</h2>
        <h1>路特斯车辆订阅管理系统</h1>
        <div className={styles.formContainer}>
          <Form onFinish={handleSubmit} layout="vertical">
            <Form.Item label="用户名" name="username" rules={[{required: true,message:'请输入账户'}]}>
              <Input
                size="large"
                placeholder="请输入账户"/>
            </Form.Item>
            <Form.Item label="密码" name="password" rules={[{required: true,message:'请输入密码'}]}>
              <Input
                size="large"
                type="password"
                placeholder="请输入密码"/>
            </Form.Item>
            <Form.Item style={{paddingTop:10}}>
              <Button block size="large" type="primary" className={styles.loginBtn}  htmlType="submit" loading={loading}>登录</Button>
            </Form.Item>
          </Form>
        </div>
        <div className={styles.footer}>
          Copyright <CopyrightOutlined/> 2024 By Yonda
        </div>
      </div>
    </div>
  )
}
