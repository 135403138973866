

export const isMobile = /Android|iPhone/i.test(navigator.userAgent)

// 前端页面的权限判断(仅作为前端功能展示的控制，具体权限控制应在后端实现)
export function hasPermission(strCode) {
  if (localStorage.getItem('is_supper') === 'true') return true;
  let permStr = localStorage.getItem('permissions');
  if (!strCode || !permStr) return false;
  const permissions = permStr.split(',');
  for (let or_item of strCode.split('|')) {
    if (isSubArray(permissions, or_item.split('&'))) {
      return true
    }
  }
  return false
}

// 组合多个className， example: <div className={clsNames(css.list, isTop && css.border)}>
export function clsNames(...args) {
  return args.filter(x => x).join(' ')
}

function isInclude(s, keys) {
  if (!s) return false
  if (Array.isArray(keys)) {
    for (let k of keys) {
      k = k.toLowerCase()
      if (s.toLowerCase().includes(k)) return true
    }
    return false
  } else {
    let k = keys.toLowerCase()
    return s.toLowerCase().includes(k)
  }
}

// 字符串包含判断
export function includes(s, keys) {
  if (Array.isArray(s)) {
    for (let i of s) {
      if (isInclude(i, keys)) return true
    }
    return false
  } else {
    return isInclude(s, keys)
  }
}

//  数组包含关系判断
export function isSubArray(parent, child) {
  for (let item of child) {
    if (!parent.includes(item.trim())) {
      return false
    }
  }
  return true
}

// 转换数字至人民币大写
export function numToCny(n) {
  const fraction = ['角', '分'];
  const digit = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
  const unit = [['元', '万', '亿'], ['', '拾', '佰', '仟']];
  const head = n < 0 ? '欠' : '';
  n = Math.abs(n);

  let s = '';

  for (let i = 0; i < fraction.length; i++) {
    s += (digit[Math.floor(n * 10 * Math.pow(10, i)) % 10] + fraction[i]).replace(/零./, '');
  }
  s = s || '整';
  n = Math.floor(n);

  for (let i = 0; i < unit[0].length && n > 0; i++) {
    let p = '';
    for (let j = 0; j < unit[1].length && n > 0; j++) {
      p = digit[n % 10] + unit[1][j] + p;
      n = Math.floor(n / 10);
    }
    s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s;
  }
  return head + s.replace(/(零.)*零元/, '元').replace(/(零.)+/g, '零').replace(/^整$/, '零元整');
}

// 拼接oss的访问链接
export function signOSSLink(path, params = {}) {
  let paramsStr = '';
  for (let [key, value] of Object.entries(params)) {
    paramsStr += `&${key}=${encodeURIComponent(value)}`
  }
  return `/api/oss/${path}?x-token=${localStorage.getItem('token')}${paramsStr}`
}

// 用于替换toFixed方法，去除toFixed方法多余的0和小数点
export function trimFixed(data, bit) {
  return String(data.toFixed(bit)).replace(/0*$/, '').replace(/\.$/, '')
}

// 日期  2021-07-21
export function humanDate(date) {
  const now = date || new Date();
  let month = now.getMonth() + 1;
  let day = now.getDate();
  return `${now.getFullYear()}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`
}

// 时间 2021-07-21 17:30:30
export function humanTime(date) {
  const now = date || new Date();
  const hour = now.getHours() < 10 ? '0' + now.getHours() : now.getHours();
  const minute = now.getMinutes() < 10 ? '0' + now.getMinutes() : now.getMinutes();
  const second = now.getSeconds() < 10 ? '0' + now.getSeconds() : now.getSeconds();
  return `${humanDate()} ${hour}:${minute}:${second}`
}

// 导出excel
export function blobToExcel(data, filename) {
  const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  document.body.appendChild(link);
  const evt = document.createEvent("MouseEvents");
  evt.initEvent("click", false, false);
  link.dispatchEvent(evt);
  document.body.removeChild(link);
}

// js对象和数组深拷贝  a = [[2], []],  b = deepCopy(a); b.push(2)
export function deepCopy(obj) {
  if (Array.isArray(obj)) {
    let result = [];
    for (let item of obj) {
      result.push(deepCopy(item))
    }
    return result
  } else if (typeof obj === 'object' && obj !== null) {
    let result = {};
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        result[key] = deepCopy(obj[key])
      }
    }
    return result
  } else {
    return obj
  }
}

// js对象数组去重 iteratee 为去重的key
export function uniqBy(arr, iteratee) {
  const res = new Map()
  return arr.filter(a => !res.has(a[iteratee]) && res.set(a[iteratee], 1))
}

//获得字符串字节长度
export function getLength(val) {
  let str = val.toString();
  let bytesCount = 0;
  for (let i = 0, n = str.length; i < n; i++) {
    let c = str.charCodeAt(i);
    if ((c >= 0x0001 && c <= 0x007e) || (0xff60 <= c && c <= 0xff9f)) {
      bytesCount += 1;
    } else {
      bytesCount += 2;
    }
  }

  return bytesCount;
}

// 判断是否有有效值。false, 0,'0', 都算有效值
export function checkValue(param) {
  return param !== undefined && param !== null && param !== ''
}

// 判断是否有有效值。false, 0,'0', 都算有效值
// 传值为单个值时，判断当前值是否有效
// 传值为多个值的数组时，数组中包含有效的值时返回true, 全部无效时返回false
// param 或 [param,param,...]
export function hasValue(param,) {
  let isTure = param !== undefined && param !== null && param !== ''
  if (isTure && typeof param === "object") {
    return param.map(v => hasValue(v)).includes(true)
  }

  return isTure
}

// 日期时间字符串转为日期，可为空，不合法时直接返回
export function nullableHumanDate(dateStr) {
  if (!dateStr) return ''
  let d = new Date(dateStr)
  if (d) {
    return humanDate(d)
  } else {
    return dateStr
  }

}

// 采购模块：列表筛选项数据处理
export function makeFilterData(options, summary, showAll = true) {
  if (!checkValue(options)) return []

  let resOptions = deepCopy(options)
  resOptions.forEach(v => {
    if (checkValue(summary[v.summary_key])) {
      v.label = `${v.label}(${summary[v.summary_key]})`
    }
  })

  if (showAll) {
    resOptions = resOptions.concat({label: '全部', value: ''})
  }

  return resOptions
}