import React, { useState } from 'react';
import { Modal, Form, Input, message, Button } from 'antd';
import { http } from 'libs';
import store from './store';
import { Upload } from "components";

export default function IForm() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const actions = {
    approve: '整备审核',
    car_cover: '上传车辆照片',
  }
  const footer_button = [
    <Button onClick={store.vSet('formVisible', false)} >
      取消
    </Button>,
    <Button style={{ backgroundColor: '#ff4d4f', color: '#fff', border: 'none' }} onClick={() => handleSubmit('reject')} danger>
      驳回
    </Button>,
    <Button style={{ backgroundColor: '#1890FF', color: '#fff', border: 'none' }} onClick={() => handleSubmit('pass')}>
      通过
    </Button>
  ]

  function handleSubmit(v) {
    setLoading(true);
    const formData = form.getFieldsValue();
    formData['id'] = store.record.id;
    formData['action'] = v
    http.patch('/web/lutesi/prepare/', formData)
      .then(() => {
        message.success('操作成功');
        store.set('formVisible', false);
        store.fetchRecords()
        store.fetchSummary()
      }, () => setLoading(false))
  }

  return (
    <React.Fragment>
      {store.formAction !== 'car_cover' ? null : (
        <Modal
          open
          maskClosable={false}
          title={actions[store.formAction]}
          onCancel={store.vSet('formVisible', false)}
          confirmLoading={loading}
          onOk={form.submit}>
          <Form form={form}
            onFinish={handleSubmit}
            initialValues={store.record}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 14 }}>
            <Form.Item name="third_car_cover" label='车辆照片' rules={[{ required: true }]}>
              <Upload path="car_cover" />
            </Form.Item>
          </Form>
        </Modal>
      )}
      {store.formAction !== 'approve' ? null : (<Modal
        open
        maskClosable={false}
        title={actions[store.formAction]}
        onCancel={store.vSet('formVisible', false)}
        confirmLoading={loading}
        footer={footer_button}
      >
        <Form form={form} initialValues={store.record} labelCol={{ span: 4 }} wrapperCol={{ span: 17 }}>
          <div style={{ width: '80%', color: '#000000', fontSize: 14, backgroundColor: '#F4F4F4', marginLeft: 35, marginBottom: 10, padding: 20 }}>
            <div>{store.record.prepare_items.map(x => <div style={{ display: 'flex', marginBottom: 10 }}><div>整备项目：{x.name}</div> <div style={{ marginLeft: 50 }}>预估整备费用：{x.money}元</div></div>)}</div>
            <div>预估整备时间：{store.record.prepare_days}天</div>
          </div>
          <Form.Item name="remarks" label="备注">
            <Input.TextArea placeholder="请输入" />
          </Form.Item>
        </Form>
      </Modal>)}
    </React.Fragment>
  )
}