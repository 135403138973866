import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {Card, Form, DatePicker, Spin} from 'antd';
import {AuthButton, Breadcrumb} from 'components';
import {http, blobToExcel, data} from "libs";

export default observer(function () {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // channelStore.fetchRecords()
    form.setFieldsValue({
      pre_delivery_time: data.rangePresets['本月'],
      pre_return_time: data.rangePresets['本月']
    })
  }, [])

  function handleSubmit(formData) {
    let format = 'YYYY-MM-DD'
    setLoading(true);
    if (formData.pre_delivery_time?.length === 2) {
      formData.pre_delivery_time = [formData.pre_delivery_time[0].format(format), formData.pre_delivery_time[1].format(format)]
    }
    if (formData.pre_return_time?.length === 2) {
      formData.pre_return_time = [formData.pre_return_time[0].format(format), formData.pre_return_time[1].format(format)]
    }

    http.post('/web/lutesi/export/', formData, {
      responseType: 'blob',
      timeout: 120000
    }).then(res => {
      let suffix = 'xlsx';
      let title = '路特斯'
      let deliveryRange = formData.pre_delivery_time?.length === 2 ? (`预定交付时间 ${formData.pre_delivery_time[0]} 至 ${formData.pre_delivery_time[1]}`) : []
      let returnRange = formData.pre_return_time?.length === 2 ? (`预定收车时间 ${formData.pre_return_time[0]} 至 ${formData.pre_return_time[1]}`) : []
      let name = [title, deliveryRange,  returnRange]
      name = name.join(' ').trim() + '数据报表' + '.' + suffix

      blobToExcel(res.data, name)
    }, () => setLoading(false))
      .finally(() => setLoading(false))
  }

  return (
    <React.Fragment>
      <Breadcrumb>
        <Breadcrumb.Item>报表导出</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <Form form={form} layout="inline" onFinish={handleSubmit}>
          {/*rules={[{required: true}]}*/}
          <Form.Item name="pre_delivery_time" label="预定交付时间" >
            <DatePicker.RangePicker ranges={data.rangePresets}/>
          </Form.Item>
          <Form.Item name="pre_return_time" label="预定收车时间" >
            <DatePicker.RangePicker ranges={data.rangePresets}/>
          </Form.Item>
          <AuthButton auth="report.report_btn" htmlType="submit" type="primary" loading={loading}>导出</AuthButton>
        </Form>
      </Card>
      {loading &&  <div style={{textAlign: "center", marginTop: 100}}><Spin tip="请耐心等待下载完成...">{null}</Spin></div>}
    </React.Fragment>
  )
})