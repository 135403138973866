import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { UploadOutlined } from '@ant-design/icons';
import { Modal, Form, Upload, Button, Tooltip, Select } from 'antd';
import { http, includes } from 'libs';
import store from './store';

export default observer(function () {
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm()

  useEffect(() => {
  }, [])


  function handleSubmit(formData) {
    const data = new FormData();
    // let arr = ['', '']
    data.append('file', fileList[0]);

    setLoading(true);
    http.post('/web/lutesi/stock/import/', data, { timeout: 120000 })
      .then(res => {
        if (res.file_path) {
          window.open(`web/` + res.file_path)
        }
        Modal.info({
          title: '导入结果',
          content: <Form labelCol={{ span: 7 }} wrapperCol={{ span: 14 }}>
            <Form.Item style={{ margin: 0 }} label="导入成功">{res.success}</Form.Item>
            {res['invalid'].length > 0 && <Form.Item style={{ margin: 0, color: '#1890ff' }} label="无效数据">
              <Tooltip title={`相关行：${res['invalid'].join(', ')}`}>{res['invalid'].length}</Tooltip>
            </Form.Item>}
          </Form>,
          onOk: () => {
            store.fetchRecords();
            store.fetchSummary()
            store.importVisible = false
          }
        })
      })
      .finally(() => setLoading(false))
  }

  function handleUpload(v) {
    if (v.fileList.length === 0) {
      setFileList([])
    } else {
      setFileList([v.file])
    }
  }

  return (
    <Modal
      open
      maskClosable={false}
      title={'外部车辆导入'}
      onCancel={() => store.importVisible = false}
      confirmLoading={loading}
      okButtonProps={{ disabled: !fileList.length }}
      onOk={form.submit}>
      <Form form={form} onFinish={handleSubmit} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }}>
        <Form.Item label="模板下载" extra="请下载使用该模板，填充数据后导入。">
          <a href="/路特斯车辆导入模板.xlsx">路特斯车辆导入模板.xlsx</a>
        </Form.Item>
        <Form.Item required label="导入数据">
          <Upload
            name="file"
            accept=".xls, .xlsx"
            fileList={fileList}
            beforeUpload={() => false}
            onChange={handleUpload}>
            {fileList.length === 0 && (
              <Button><UploadOutlined /> 点击上传</Button>
            )}
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
})
