import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Modal, Form, Input, message, Row, Col, Select, Cascader, Table } from 'antd';
import { http, includes } from 'libs';
import store from './store';
import { ADatePicker } from "components";
import { city } from "libs/divisions";
import cStore from '../stock/store'

export default observer(function IForm() {

    useEffect(() => {
        getOrders(store.record.vehicle_id)
    }, [])


    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [orders, setOrders] = useState([])

    const columns = [
        {
            title: '已租赁订单号',
            dataIndex: 'sn'
        }, {
            title: '交付日期',
            dataIndex: 'pre_delivery_time'
        }, {
            title: '收车日期',
            dataIndex: 'pre_return_time'
        }, {
            title: '订单状态',
            dataIndex: 'status_alias'
        }
    ]


    function handleSearch(v) {
        if (store.users.filter(x => x.phone === v).length) {
            form.setFieldValue('name', store.users.filter(x => x.phone === v)[0].name)
        } else {
            form.setFieldValue('name', null)
        }
    }

    function getOrders(v) {
        http.get(`/web/lutesi/order/?vehicle_id=${v}`)
            .then(res => setOrders(res))
    }

    function handleSubmit() {
        setLoading(true);
        const formData = form.getFieldsValue();
        formData['id'] = store.record.id;
        http.post('/web/lutesi/order/', formData)
            .then(() => {
                message.success('操作成功');
                store.set('addformVisible', false);
                store.fetchRecords()
                store.fetchSummary()
            }, () => setLoading(false))
    }

    return (
        <Modal
            open
            width={800}
            maskClosable={false}
            title={'编辑订单'}
            onCancel={store.vSet('addformVisible', false)}
            confirmLoading={loading}
            onOk={handleSubmit}>
            <Form form={form} initialValues={store.record} labelCol={{ span: 4 }} wrapperCol={{ span: 18 }}>
                <Row>
                    <Col span={12}>
                        <Form.Item labelCol={{ span: 8 }} wrapperCol={{ span: 14 }} required name="phone" label="客户手机号">
                            <Input disabled onChange={e => handleSearch(e.target.value)} />
                        </Form.Item></Col>
                    <Col span={11}>
                        <Form.Item labelCol={{ span: 8 }} wrapperCol={{ span: 14 }} required name="name" label="客户名称" >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item required name="vehicle_id" label="租赁车辆">
                    <Select
                        showSearch
                        onChange={v => getOrders(v)}
                        filterOption={(val, options) => includes(options.children, val)}
                        placeholder="请选择">
                        {
                            store.cars.map((v, i) => (
                                <Select.Option value={v.id} key={i}>{v.car_full_model}/{v.car_plate_number}</Select.Option>
                            ))
                        }
                    </Select>
                </Form.Item>
                {orders && orders.length > 0 ? <Row style={{ marginBottom: 20 }}>
                    <Col span={2}>
                    </Col>
                    <Col span={20}>
                        <Table
                            dataSource={orders}
                            columns={columns}
                            pagination={false}
                        />
                    </Col>
                </Row> : null}
                <Row>
                    <Col span={12}>
                        <Form.Item labelCol={{ span: 8 }} wrapperCol={{ span: 14 }} required name="pre_delivery_time" label="预定交付时间">
                            <ADatePicker
                                showTime
                                allowClear={false}
                                style={{ width: '100%' }}
                                format="YYYY-MM-DD HH:mm"
                                placeholder="请选择日期" />
                        </Form.Item>
                        <Form.Item labelCol={{ span: 8 }} wrapperCol={{ span: 14 }} name="pre_delivery_region" label="交付省市区">
                            <Cascader options={city}
                                fieldNames={{ label: 'name', value: 'name' }}
                                displayRender={label => label.join(' ')}
                                placeholder="请选择" />
                        </Form.Item>
                        <Form.Item labelCol={{ span: 8 }} wrapperCol={{ span: 14 }} name="pre_delivery_detail" label="交付详细地址">
                            <Input.TextArea placeholder="请输入" />
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item labelCol={{ span: 8 }} wrapperCol={{ span: 14 }} required name="pre_return_time" label="预定收车时间">
                            <ADatePicker
                                showTime
                                allowClear={false}
                                style={{ width: '100%' }}
                                format="YYYY-MM-DD HH:mm"
                                placeholder="请选择日期" />
                        </Form.Item>
                        <Form.Item labelCol={{ span: 8 }} wrapperCol={{ span: 14 }} name="pre_return_region" label="收车省市区">
                            <Cascader options={city}
                                fieldNames={{ label: 'name', value: 'name' }}
                                displayRender={label => label.join(' ')}
                                placeholder="请选择" />
                        </Form.Item>
                        <Form.Item labelCol={{ span: 8 }} wrapperCol={{ span: 14 }} name="pre_return_detail" label="收车详细地址">
                            <Input.TextArea placeholder="请输入" />
                        </Form.Item>
                    </Col>
                </Row>

            </Form>
        </Modal >
    )
})