import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Image, Typography, Tag, Card, Table } from 'antd';
import { AuthDiv } from 'components';
import { data } from 'libs';
import store from './store';
import moment from 'moment';

export default observer( function IForm() {
  const maxWidth = {
    wordBreak: 'break-all',
    maxWidth: '12vw'
  }
  useEffect(() => {
    setRecords(store.record.orders)
  }, [store.record])
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const columns = [
    {
      title: '下单信息',
      render: info => <div style={maxWidth}>
        <div>下单时间：{info.created_at}</div>
        <div style={{ color: '#717171' }}>租赁天数：{info.rent_days}</div>
        <div style={{ color: '#717171' }}>订单号：{info.sn}</div>
      </div>
    }, {
      title: '车辆照片',
      dataIndex: 'third_car_cover',
      render: (value, info) => value ?
        <Image src={info.third_car_cover} fallback={data.picFallBack} width={140} style={{ maxHeight: 140 }} />
        : (
          <AuthDiv auth="users.car_cover" onClick={() => store.showForm(info, 'car_cover')}>
            <Typography.Link>上传</Typography.Link>
          </AuthDiv>
        )
    }, {
      title: '车辆详情',
      render: info => <div style={maxWidth}>
        <span><div>{info.car_full_model}</div></span>
        <div style={{ color: '#717171' }}>{info.car_plate_number} {info.car_color}</div>
        <div style={{ color: '#717171' }}>{info.car_mileage}</div>
        <div style={{ color: '#717171' }}>{info.car_vin}</div>
        <div>{<span><Tag color="#000000">{info.third_car_type}</Tag></span>}</div>
      </div>
    }, {
      title: '交付信息',
      render: info => <div style={maxWidth}>
        {info.pre_delivery_time ?
          <div>
            {new Date(info.pre_delivery_time).getFullYear() === (new Date()).getFullYear() ?
              moment(info.pre_delivery_time).format('MM-DD HH:mm') : info.pre_delivery_time}</div>
          : null}
        <div style={{ color: '#717171' }}>{info.pre_delivery_region[0]}{info.pre_delivery_region[1]}{info.pre_delivery_region[2]}</div>
        <div style={{ color: '#717171' }}>{info.pre_delivery_detail}</div>
        {info.delivery_by_name ? <div style={{ color: '#717171' }}>运营专员：{info.delivery_by_name}</div> : null}
        {/* {info.delivery_images?.length ? <AuthDiv onClick={() => store.showForm(info, 'delivery_images')}>
          <Typography.Link>查看交付签字</Typography.Link>
        </AuthDiv> : null} */}
      </div>
    }, {
      title: '收车信息',
      render: info => <div style={maxWidth}>
        {info.pre_return_time ? <div>
          {new Date(info.pre_return_time).getFullYear() === (new Date()).getFullYear() ?
            moment(info.pre_return_time).format('MM-DD HH:mm') : info.pre_return_time}
        </div> : null}
        <div style={{ color: '#717171' }}>{info.pre_return_region[0]}{info.pre_return_region[1]}{info.pre_return_region[2]}</div>
        <div style={{ color: '#717171' }}>{info.pre_return_detail}</div>
        {info.return_by_name ? <div style={{ color: '#717171' }}>运营专员：{info.return_by_name}</div> : null}
        {/* {info.return_images?.length ? <AuthDiv onClick={() => Ustore.showForm(info, 'return_images')}>
          <Typography.Link>查看收车签字</Typography.Link>
        </AuthDiv> : null} */}
      </div>
    }, {
      title: '超速记录',
      render: info => <div style={maxWidth}>
        {info.over_speed_times ? <div>超速{info.over_speed_times}次</div> : null}
        {info.over_speed_times ? <AuthDiv auth="" /* onClick={() => store.showForm(info, 'speed')} */>
          <Typography.Link>查看超速记录</Typography.Link>
        </AuthDiv> : null}
      </div>
    },
  ]




  return (
    <Card >
      <Table dataSource={records}
        rowKey="id"
        columns={columns}
        loading={loading}
        pagination={false} />
    </Card>
  )
})