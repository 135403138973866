import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Input, Select,Cascader } from 'antd';
import { SearchForm, Breadcrumb } from 'components';
import Table from './Table';
import Form from './Form';
import store from './store';
import { city } from "libs/divisions1";

export default observer(function () {
  useEffect(() => {
    store.fetchRecords()
    store.fetchSummary()
  }, [store.page, store.pageSize])

  useEffect(() => {
    if (store.page === 1) {
      store.fetchRecords()
    } else {
      store.set('page', 1)
    }
  }, [store.f_tab])

  return (
    <React.Fragment>
      <Breadcrumb>
        <Breadcrumb.Item>整备管理</Breadcrumb.Item>
      </Breadcrumb>
      <SearchForm reset={store.resetSearch} search={store.handleSearch}>
        <SearchForm.Item title="车牌号/VIN">
          <Input allowClear value={store.f_key} onChange={store.eSet('f_key')} placeholder="请输入" />
        </SearchForm.Item>
        <SearchForm.Item title="整备状态">
          <Select allowClear value={store.f_status} onChange={store.eSet('f_status')} placeholder="请选择">
            <Select.Option value={'1'}>待申请</Select.Option>
            <Select.Option value={'2'}>待审核</Select.Option>
            <Select.Option value={'3'}>整备中</Select.Option>
            <Select.Option value={'4'}>已完成</Select.Option>
          </Select>
        </SearchForm.Item>
        <SearchForm.Item title="所在城市">
          <Cascader options={city}
            changeOnSelect
            value={store.location}
            onChange={store.eSet('location')}
            fieldNames={{ label: 'name', value: 'name' }}
            displayRender={label => label.join(' ')}
            placeholder="请选择" />
        </SearchForm.Item>
      </SearchForm>
      <Table />
      {store.formVisible && <Form />}
    </React.Fragment>
  )
})