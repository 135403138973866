import React from 'react';
import { observer } from 'mobx-react';
import { Radio, Image, Typography, Tag, message, Modal } from 'antd';
import { TableCard, AuthDiv, AuthButtonV } from 'components';
import { http, data, history } from 'libs';
import store from './store';
import moment from 'moment';


export default observer(function () {
  const maxWidth = {
    wordBreak: 'break-all',
    maxWidth: '12vw'
  }

  const tabs = [
    { value: "1", label: `已下单待提车(${store.summary["kh_dtc"] || 0})` },
    { value: "2", label: `已交付待还车(${store.summary["kh_dhc"] || 0})` },
    { value: "3", label: `已还车(${store.summary["kh_yhc"] || 0})` },
    { value: "4", label: `已关闭(${store.summary["kh_yqx"] || 0})` },
    { value: "", label: `全部(${store.summary["kh_qb"] || 0})` },
  ]

  const columns = [
    {
    title: '客户信息',
    render: info => <div style={maxWidth}>
      <div>{info.name}</div>
      <div style={{ color: '#717171' }}>{info.phone}</div>
      <div style={{ color: '#717171' }}>{info.remarks}</div>
    </div>
  }, {
    title: '车辆照片',
    dataIndex: 'third_car_cover',
    render: (value, info) => value ?
      <Image src={info.third_car_cover} fallback={data.picFallBack} width={140} style={{ maxHeight: 140 }} />
      : (
        <AuthDiv auth="users.car_cover" onClick={() => store.showForm(info, 'car_cover')}>
          <Typography.Link>上传</Typography.Link>
        </AuthDiv>
      )
  }, {
    title: '车辆详情',
    render: info => <div style={maxWidth}>
      <span><div>{info.car_full_model}</div></span>
      <div style={{ color: '#717171' }}>{info.car_plate_number} {info.car_color}</div>
      <div style={{ color: '#717171' }}>{info.car_mileage}</div>
      <div style={{ color: '#717171' }}>{info.car_vin}</div>
      <div>{<span><Tag color="#000000">{info.third_car_type}</Tag></span>}</div>
    </div>
  }, {
    title: '下单信息',
    render: info => <div style={maxWidth}>
      <div>下单时间：{info.created_at}</div>
      <div style={{ color: '#717171' }}>租赁天数：{info.rent_days}</div>
      <div style={{ color: '#717171' }}>订单号：{info.sn}</div>
    </div>
  }, {
    title: '交付信息',
    render: info => <div style={maxWidth}>
      {info.pre_delivery_time ?
        <div>
          {new Date(info.pre_delivery_time).getFullYear() === (new Date()).getFullYear() ?
            moment(info.pre_delivery_time).format('MM-DD HH:mm') : info.pre_delivery_time}</div>
        : null}
      <div style={{ color: '#717171' }}>{info.pre_delivery_region[0]}{info.pre_delivery_region[1]}{info.pre_delivery_region[2]}</div>
      <div style={{ color: '#717171' }}>{info.pre_delivery_detail}</div>
      {info.delivery_by_name ? <div style={{ color: '#717171' }}>运营专员：{info.delivery_by_name}</div> : null}
      {info.delivery_images?.length ? <AuthDiv onClick={() => store.showForm(info, 'delivery_images')}>
        <Typography.Link>查看交付签字</Typography.Link>
      </AuthDiv> : null}
    </div>
  }, {
    title: '收车信息',
    render: info => <div style={maxWidth}>
      {info.pre_return_time ? <div>
        {new Date(info.pre_return_time).getFullYear() === (new Date()).getFullYear() ?
          moment(info.pre_return_time).format('MM-DD HH:mm') : info.pre_return_time}
      </div> : null}
      <div style={{ color: '#717171' }}>{info.pre_return_region[0]}{info.pre_return_region[1]}{info.pre_return_region[2]}</div>
      <div style={{ color: '#717171' }}>{info.pre_return_detail}</div>
      {info.return_by_name ? <div style={{ color: '#717171' }}>运营专员：{info.return_by_name}</div> : null}
      {info.return_images?.length ? <AuthDiv onClick={() => store.showForm(info, 'return_images')}>
        <Typography.Link>查看收车签字</Typography.Link>
      </AuthDiv> : null}
    </div>
  }, {
    title: '整备状态',
    render: info => <div style={maxWidth}>
      <div>{info.prepare_status_alias}</div>
      <div style={{ color: '#717171' }}>{info.prepare_time}</div>
    </div>
  }, {
    title: '检测状态',
    render: info => <div style={maxWidth}>
      <div>{info.detect_status_alias}</div>
      <div style={{ color: '#717171' }}>{info.detect_time}</div>
    </div>
  }, {
    title: '操作',
    fixed: 'right',
    align: 'center',
    render: info => <>

      <AuthButtonV auth="users.edit"
        onClick={() => store.showForm(info, 'order')}
      >
        编辑
      </AuthButtonV>
      <AuthButtonV auth="users.callback"
      >
        回访
      </AuthButtonV>
      <AuthButtonV auth="users.detail"
        onClick={() => gotoDetail(info)}>
        客户详情
      </AuthButtonV>
      <AuthButtonV auth="users.handle"
        onClick={() => handleActive(info)}
        hidden={['1', '2', '3'].includes(info.return_status)}
      >
        {info.delivery_status === '4' ? '开启订单' : <span style={{ color: '#FF2600' }}>关闭订单</span>}
      </AuthButtonV>
    </>
  },]

  // const columnsFilter =  columns.filter(item => item.title === '客户信息' || item.title === '下单信息' || item.title === '操作')
  //全部时显示内容
  const columnsAll = [
    {
      title: '客户信息',
      render: info => <div style={maxWidth}>
        <div>{info.name}</div>
        <div style={{ color: '#717171' }}>{info.phone}</div>
        <div style={{ color: '#717171' }}>{info.remarks}</div>
      </div>
    },
    {
      title: '注册时间',
      dataIndex: 'customer_created_at'
    },
    {
      title: '下单信息',
      render: info => <div style={maxWidth}>
        <div>下单时间：{info.created_at}</div>
        <div style={{ color: '#717171' }}>租赁天数：{info.rent_days}</div>
        <div style={{ color: '#717171' }}>订单号：{info.sn}</div>
      </div>
    },
    {
      title: '操作',
      fixed: 'right',
      align: 'center',
      render: info => <>

        <AuthButtonV auth="users.edit"
                     onClick={() => store.showForm(info, 'order')}
        >
          编辑
        </AuthButtonV>
        <AuthButtonV auth="users.callback"
                     onClick={() => store.showForm(info, 'callback')}
        >
          回访
        </AuthButtonV>
        <AuthButtonV auth="users_detail"
                     onClick={() => gotoDetail(info)}>
          客户详情
        </AuthButtonV>
        <AuthButtonV auth="users.handle"
                     onClick={() => handleActive(info)}
                     hidden={['1', '2', '3'].includes(info.return_status)}
        >
          {info.delivery_status === '4' ? '开启订单' : <span style={{ color: '#FF2600' }}>关闭订单</span>}
        </AuthButtonV>
      </>
    }

  ]

  function gotoDetail(info) {
    history.push(`/user/detail/${info.id}`)
  }

  function handleActive(info) {
    Modal.confirm({
      title: '操作确认',
      content: `确定要${info['delivery_status'] === '4' ? '开启' : '关闭'}订单【${info['sn']}】?`,
      onOk: () => {
        return http.patch('/web/lutesi/customer/', { id: info.id, action: info['delivery_status'] === '4' ? 'restart' : 'close' })
          .then(() => {
            message.success('操作成功');
            store.fetchRecords()
            store.fetchSummary()
          })
      }
    })
  }


  return (
    <TableCard
      title="客户列表"
      rowKey="id"
      loading={store.fetching}
      dataSource={store.records}
      columns={store.f_tab !== "" ? columns : columnsAll}
      pagination={{
        current: store.page,
        onChange: (page, pageSize) => {
          store.set('page', page)
          store.set('pageSize', pageSize)
        },
        showSizeChanger: true,
        showLessItems: true,
        hideOnSinglePage: false,
        total: store.pageTotal,
        showTotal: (total, range) => `显示第 ${range[0]} 到第 ${range[1]} 条记录，总共 ${total} 条记录`,
        pageSizeOptions: ['10', '20', '50', '100']
      }}
      onReload={store.fetchRecords}
      actions={[
        <Radio.Group optionType={"button"}
          options={tabs}
          value={store.f_tab}
          onChange={store.eSet('f_tab')}
        />
      ]} />
  )
})
