import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Table, Space, Divider, Popover, Checkbox, Button } from 'antd';
import { ReloadOutlined, SettingOutlined, FullscreenOutlined } from '@ant-design/icons';
import { hasPermission } from 'libs';
import styles from './index.module.less';

function Footer(props) {
  const actions = props.actions || [];
  const length = props.selected.length;
  return length > 0 ? (
    <div className={styles.tableFooter}>
      <div className={styles.left}>已选择 <span>{length}</span> 项</div>
      <Space size="middle">
        {actions.map((item, index) => (
          <React.Fragment key={index}>{item}</React.Fragment>
        ))}
      </Space>
    </div>
  ) : null
}

function Header(props) {
  const columns = useMemo(() => props.columns || [], [props.columns]);
  const actions = props.actions || [];
  const onFieldsChange = props.onFieldsChange;
  const [fields, _setFields] = useState([]);
  const [defaultFields, setDefaultFields] = useState([]);
  const [checkAll, setCheckAll] = useState(false);

  const Fields = () => {
    return (
      <Checkbox.Group value={fields} onChange={handleCheck}>
        {columns.map((item, index) => (
          <Checkbox disabled={!item.title} value={index} key={index}>{item.title}</Checkbox>
        ))}
      </Checkbox.Group>
    )
  }

  useEffect(() => {
    const data = [];
    for (let [index, item] of columns.entries()) {
      if (!item.hide && (!item.auth || hasPermission(item.auth))) {
        data.push(index)
      }
    }
    setDefaultFields(data);
    _setFields(data);
    onFieldsChange(data)
  }, [columns, onFieldsChange])

  function setFields(v) {
    _setFields(v);
    onFieldsChange(v)
  }

  function handleCheckAll(e) {
    if (e.target.checked) {
      setCheckAll(true);
      setFields(columns.map((_, index) => index))
    } else {
      setCheckAll(false);
      setFields([])
    }
  }

  function handleCheck(v) {
    setFields(v);
    if (v.length === 0) {
      setCheckAll(false)
    } else if (v.length === columns.length) {
      setCheckAll(true)
    }
  }

  function handleFullscreen() {
    if (props.rootRef.current && document.fullscreenEnabled) {
      if (document.fullscreenElement) {
        document.exitFullscreen()
      } else {
        props.rootRef.current.requestFullscreen()
      }
    }
  }

  return (
    <div className={styles.toolbar}>
      <div className={styles.title}>{props.title}</div>
      <div className={styles.option}>
        <Space size="middle" style={{marginRight: 10}}>
          {actions.map((item, index) => (
            <React.Fragment key={index}>{item}</React.Fragment>
          ))}
        </Space>
        {actions.length ? <Divider type="vertical"/> : null}
        <Space className={styles.icons}>
          <ReloadOutlined onClick={props.onReload}/>
          <Popover
            arrowPointAtCenter
            destroyTooltipOnHide={{keepParent: false}}
            title={[
              <Checkbox
                key="1"
                checked={checkAll}
                indeterminate={![0, columns.length].includes(fields.length)}
                onChange={handleCheckAll}>列展示</Checkbox>,
              <Button key="2" type="link" style={{padding: 0}} onClick={() => setFields(defaultFields)}>重置</Button>
            ]}
            overlayClassName={styles.tableFields}
            trigger="click"
            placement="bottomRight"
            content={<Fields/>}>
            <SettingOutlined/>
          </Popover>
          <FullscreenOutlined onClick={handleFullscreen}/>
        </Space>
      </div>
    </div>
  )
}

function TableCard(props) {
  const rootRef = useRef();
  const columns = props.columns;
  const batchActions = props.batchActions;
  const selected = props.selected;
  const [fields, setFields] = useState([]);

  return (
    <div ref={rootRef} className={[styles.tableCard, 'tableCard'].join(' ')}>
      <Header
        title={props.title}
        columns={columns}
        actions={props.actions}
        rootRef={rootRef}
        onFieldsChange={setFields}
        onReload={props.onReload}/>
      <Table
        rowKey={props.rowKey || 'id'}
        loading={props.loading}
        columns={columns.filter((_, index) => fields.includes(index))}
        dataSource={props.dataSource}
        rowSelection={props.rowSelection}
        expandable={props.expandable}
        pagination={props.pagination}/>
      {selected.length ? <Footer selected={selected} actions={batchActions}/> : null}
    </div>
  )
}

TableCard.Header = Header;
TableCard.Footer = Footer;
TableCard.defaultProps = {
  columns: [],
  selected: [],
  batchActions: [],
}
export default TableCard