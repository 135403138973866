import React from 'react';
import { observer } from 'mobx-react';
import { Radio, Image, Typography, Tag } from 'antd';
import { TableCard, AuthDiv, AuthButtonV } from 'components';
import { data, } from 'libs';
import store from './store';


export default observer(function () {
  const maxWidth = {
    wordBreak: 'break-all',
    maxWidth: '12vw'
  }

  const tabs = [
    { value: "1", label: `待投保(${store.summary["bx_dtb"] || 0})` },
    { value: "2", label: `已投保(${store.summary["bx_ytb"] || 0})` },
    { value: "3", label: `已脱保(${store.summary["bx_tb"] || 0})` },
    { value: "", label: `全部(${store.summary["bx_qb"] || 0})` },
  ]

  const columns = [{
    title: '车辆照片',
    dataIndex: 'third_car_cover',
    render: (value, info) => value ?
      <Image src={info.third_car_cover} fallback={data.picFallBack} width={140} style={{ maxHeight: 140 }} />
      : (
        <AuthDiv auth="insurance.car_cover" onClick={() => store.showForm(info, 'car_cover')}>
          <Typography.Link>上传</Typography.Link>
        </AuthDiv>
      )
  }, {
    title: '车辆详情',
    render: info => <div style={maxWidth}>
      <span><div style={{ color: '#000000' }}>{info.car_full_model}</div></span>
      <div style={{ color: '#717171' }}>{info.car_plate_number} {info.car_color}</div>
      <div style={{ color: '#717171' }}>{info.car_mileage}</div>
      <div style={{ color: '#717171' }}>{info.car_vin}</div>
      <div>{info.third_car_type ? <span><Tag color="#000000">{info.third_car_type}</Tag></span> : null}</div>
    </div>
  }, {
    title: '交强险',
    render: info => <div style={maxWidth}>
      <span><div style={ info.traffic.status_alias === "已脱保" ? {color: '#FF0000'} : {color: '#000000'}}>{info.traffic.status_alias}</div></span>
      {info.traffic.expired_at ? <div style={{ color: '#717171' }}>{info.traffic.expired_at}到期</div> : null}
      {info.traffic.left_days ? <div style={{ color: '#717171' }}>剩余{info.traffic.left_days < 0 ? 0 : info.traffic.left_days}天</div> : null}
      {info.traffic.url ? <Typography.Link href={info.traffic.url} target="_blank">查看</Typography.Link> : null}
    </div>
  }, {
    title: '商业险',
    render: info => <div style={maxWidth}>
      <span><div style={ info.bussiness.status_alias === "待投保" ? {color: '#FF0000'} : {color: '#000000'}}>{info.bussiness.status_alias}</div></span>
      {info.bussiness.expired_at ? <div style={{ color: '#717171' }}>{info.bussiness.expired_at}到期</div> : null}
      {info.bussiness.left_days ? <div style={{ color: '#717171' }}>剩余{info.bussiness.left_days < 0 ? 0 : info.bussiness.left_days}天</div> : null}
      {info.bussiness.url ? <Typography.Link href={info.bussiness.url} target="_blank">查看</Typography.Link> : null}
    </div>
  }, {
    title: '库存状态',
    render: info => <div style={maxWidth}>
      <div>{info.stock_status_alias}</div>
      <div style={{ color: '#717171' }}>{info.stock}</div>
    </div>
  }, {
    title: '违章',
    align: 'left',
    render: info => <div style={{wordBreak: 'break-all', maxWidth: '18vw'}}>
      <div style={{textAlign: 'left'}}>{info?.violation[0]?.content}</div>
      {info?.violation?.length > 0 &&
      <div onClick={() => store.showForm(info, 'more')} style={{textAlign: 'left',color:"#1890ff",marginTop: 8, cursor: 'pointer'}}>
        查看更多
      </div>}
    </div>
  }, {
    title: '操作',
    fixed: 'right',
    align: 'center',
    render: info => <>
      <AuthButtonV auth="insurance.add"
        onClick={() => store.showForm(info, 'add')}
      >
        录入信息
      </AuthButtonV>
      <AuthButtonV auth="insurance.url_inner"
        href={'/pages/carDetail/index.html?id=' + info.car_sn}
        target="_blank">
        查看车辆（内部版)
      </AuthButtonV>
      <AuthButtonV auth="insurance.record"
                   onClick={() => store.showForm(info, 'record')}
      >
        记录违章
      </AuthButtonV>
      {/*<AuthButtonV auth="insurance.url_out"*/}
      {/*  href={'/pages/carOutDetail/index.html?id=' + info.car_sn}*/}
      {/*  target="_blank">*/}
      {/*  查看车辆（外部版)*/}
      {/*</AuthButtonV>*/}
    </>
  },]


  return (
    <TableCard
      title="保险列表"
      rowKey="id"
      loading={store.fetching}
      dataSource={store.records}
      columns={columns}
      pagination={{
        current: store.page,
        onChange: (page, pageSize) => {
          store.set('page', page)
          store.set('pageSize', pageSize)
        },
        showSizeChanger: true,
        showLessItems: true,
        hideOnSinglePage: false,
        total: store.pageTotal,
        showTotal: (total, range) => `显示第 ${range[0]} 到第 ${range[1]} 条记录，总共 ${total} 条记录`,
        pageSizeOptions: ['10', '20', '50', '100']
      }}
      onReload={store.fetchRecords}
      actions={[
        <Radio.Group optionType={"button"}
          options={tabs}
          value={store.f_tab}
          onChange={store.eSet('f_tab')}
        />
      ]} />
  )
})
