import { makeAutoObservable } from 'mobx';
import { http } from 'libs';

class Store {
  constructor() {
    this.set = (k, v) => {
      if (v && v.nativeEvent) v = v.target.value
      this[k] = v
    }
    this.eSet = k => e => this.set(k, e)
    this.vSet = (k, v) => () => this.set(k, v)
    makeAutoObservable(this)
  }



  records = { data:[] };
  summary = {};
  users = [];
  record = {};
  page = 1;
  pageSize = 10;
  pageTotal = 0;
  fetching = false;
  formAction = '';

  f_key = null;
  type = "2";

  location = null;
  stock = null;
  f_name = '';
  f_status = '';
  f_tab = '';
  f_address = null

  pageChange = (page, size) => {
    this.records = []
    this.page = page;
    this.pageSize = size;
    this.fetchRecords()
  }

  handleSearch = () => {
    this.page = 1;
    this.records = []
    this.fetchRecords()
  }

  fetchRecords = () => {
    this.fetching = true;
    http.post('/web/lutesi/bi/', {type:this.type, city: this.location || []})
      .then(res => {
        this.set('records', res);
      })
      .finally(() => this.set('fetching', false))
  };

  fetchUsers = () => {
    http.get('/web/lutesi/customer/')
      .then(res => this.set('users', res))
  }

  resetSearch = () => {
    this.location = null;
  }
 
}

export default new Store()