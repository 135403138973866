import React, {useEffect} from 'react';
import {observer} from 'mobx-react';
import {Input, Select} from 'antd';
import {SearchForm, Breadcrumb} from 'components';
import Table from './Table';
import Form from './Form';
import store from './store';
import roleStore from '../role/store';

export default observer(function () {
  useEffect(() => {
    roleStore.fetchRecords()
    store.fetchRecords()
  }, [])

  return (
    <React.Fragment>
      <Breadcrumb>
        <Breadcrumb.Item>系统管理</Breadcrumb.Item>
        <Breadcrumb.Item>账户管理</Breadcrumb.Item>
      </Breadcrumb>
      <SearchForm reset={store.resetSearch} search={store.handelSearch}>
        <SearchForm.Item title="登录名">
          <Input allowClear value={store.f_name} onChange={store.eSet('f_name')} placeholder="请输入"/>
        </SearchForm.Item>
        <SearchForm.Item title="账户名称">
          <Input allowClear value={store.f_nickname} onChange={store.eSet('f_nickname')} placeholder="请输入"/>
        </SearchForm.Item>
        <SearchForm.Item title="状态">
          <Select allowClear value={store.f_status} onChange={store.eSet('f_status')} placeholder="请选择">
            <Select.Option value={'1'}>已启用</Select.Option>
            <Select.Option value={'2'}>已禁用</Select.Option>
          </Select>
        </SearchForm.Item>
      </SearchForm>
      <Table/>
      {store.formVisible && <Form/>}
    </React.Fragment>
  )
})
